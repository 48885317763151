.wi-modal {
position: fixed;
width: 100%;
height: 100%;
padding: 0;
backdrop-filter: blur(7.5px) saturate(200%);
-webkit-backdrop-filter: blur(7.5px) saturate(200%);
background-color: rgba(0, 0, 0, .3);
opacity: 0;
transition: .5s;
z-index: 100;
top: 0;
}
.wi-modal.wi-show {
opacity: 1;
}
.wi-modal .wi-modal-content {
width: 90%;
top: -100%;
max-width: 500px;
border-radius: var(--modal-border-radius);
border-width: var(--modal-border-width);
border-color: var(--modal-border-color);
background: var(--modal-bg);
color: var(--modal-tx);
transition-delay: .5s;
transition: .5s;
box-sizing: border-box;
}
.wi-modal.wi-show .wi-modal-content {
top: 40px;
}
.wi-modal .wi-modal-header,
.wi-modal .wi-modal-body,
.wi-modal .wi-modal-footer {
width: calc(100% - 32px);
padding: 16px;
}
.wi-modal .wi-modal-body {
max-height: calc(100vh - (40px * 2) - (16px * 6) - 24px - 40px);
overflow: scroll;
}
.wi-modal .wi-modal-header {
border-bottom:var(--modal-border-width) solid var(--modal-border-color);
}
.wi-modal .wi-modal-footer {
border-top: var(--modal-border-width) solid var(--modal-border-color);
}
.wi-modal .wi-modal-header .wi-modal-title {
width: calc(100% - 20px);
font-size: 20px;
line-height: 24px;
font-weight: 400;
}
.wi-modal .wi-modal-header .wi-modal-close {
width: 20px;
height: 24px;
line-height: 24px;
font-size: 20px;
color: var(--modal-tx);
cursor: pointer;
text-align: right;
opacity: .7;
transition: .3s;
}
.wi-modal .wi-modal-header .wi-modal-close:hover {
opacity: 1;
}
.wi-modal .wi-modal-form {
position: relative;
float: left;
width: 100%;
max-width: 100%;
padding: 0;
margin: 0;
}