#nav-mobile {
position: fixed;
width: 100%;
height: calc(100% - var(--header-height));
top: -100%;
margin-top: 0;
padding: 0;
transition: .6s;
transition-delay: .6s;
z-index: 98;
}
#nav-mobile:before {
content: '';
position: fixed;
float: left;
top: 0;
left: 0;
width: 100%;
height: 100%;
backdrop-filter: blur(7.5px) saturate(200%);
-webkit-backdrop-filter: blur(7.5px) saturate(200%);
background-color: rgba(0, 0, 0, .3);
opacity: 0;
transition: .3s;
transition-delay: .7s;
pointer-events: none;
}
#nav-mobile.show {
top: 0;
margin-top: var(--header-height);
opacity: 1;
transition-duration: .3s;
transition-delay: 0s;
}
#nav-mobile.show:before {
opacity: 1;
transition-delay: 0s;
}
#nav-mobile .content {
padding: 40px;
max-height: calc(100% - 80px);
box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
overflow: scroll;
}
#nav-mobile .nav-list {
width: 100%;
}
#nav-mobile .nav-list .nav {
position: relative;
float: left;
width: 100%;
left: -100%;
padding: calc(var(--spacer) * 4) 0;
text-decoration: none;
transition: .3s;
}
#nav-mobile .nav-list .nav:first-child { padding-top: 0; }
#nav-mobile .nav-list .nav:last-child { padding-bottom: 0; }
#nav-mobile.show .nav-list .nav{ left: 0; }
#nav-mobile.show .nav-list .nav:nth-child(1) { transition-delay: .3s; }
#nav-mobile.show .nav-list .nav:nth-child(2) { transition-delay: .4s; }
#nav-mobile.show .nav-list .nav:nth-child(3) { transition-delay: .5s; }
#nav-mobile.show .nav-list .nav:nth-child(4) { transition-delay: .6s; }
#nav-mobile.show .nav-list .nav:nth-child(5) { transition-delay: .7s; }
#nav-mobile.show .nav-list .nav:nth-child(6) { transition-delay: .8s; }
#nav-mobile.show .nav-list .nav:nth-child(7) { transition-delay: .9s; }
#nav-mobile.show .nav-list .nav:nth-child(8) { transition-delay: 1s; }
#nav-mobile.show .nav-list .nav:nth-child(9) { transition-delay: 1.05s; }
#nav-mobile.show .nav-list .nav:nth-child(10) { transition-delay: 1.10s; }
#nav-mobile.show .nav-list .nav:nth-child(11) { transition-delay: 1.15s; }
#nav-mobile.show .nav-list .nav:nth-child(12) { transition-delay: 1.20s; }
#nav-mobile.show .nav-list .nav:nth-child(13) { transition-delay: 1.25s; }
#nav-mobile.show .nav-list .nav:nth-child(14) { transition-delay: 1.30s; }
#nav-mobile.show .nav-list .nav:nth-child(15) { transition-delay: 1.35s; }
#nav-mobile.show .nav-list .nav:nth-child(16) { transition-delay: 1.40s; }
#nav-mobile.show .nav-list .nav:nth-child(17) { transition-delay: 1.45s; }
#nav-mobile.show .nav-list .nav:nth-child(18) { transition-delay: 1.50s; }
#nav-mobile.show .nav-list .nav:nth-child(19) { transition-delay: 1.55s; }
#nav-mobile.show .nav-list .nav:nth-child(20) { transition-delay: 1.60s; }