/* Position */
.p-r { position: relative !important; }
.p-a { position: absolute !important; }
.p-f { position: fixed !important; }
.p-u { position: unset !important; }

/* Float */
.f-start { float: left !important; }
.f-end { float: right !important; }
.f-none { float: none !important; }

/* Absolut position */
.top { top: 0 !important; }
.end {
float: right !important;
right: 0 !important;
}
.bottom { bottom: 0 !important; }
.start {
float: left !important;
left: 0 !important;
}

/* Center element */
.center {
position: absolute;
margin: 0 !important;
top: 50% !important;
left: 50% !important;
transform: translate(-50%,-50%) !important;
}
.c-h{
margin-top: 0 !important;
margin-bottom: 0 !important;
top: 50% !important;
transform: translateY(-50%) !important;
}
.c-w{
margin-left: 0 !important;
margin-right: 0 !important;
left: 50% !important;
transform: translateX(-50%) !important;
}

/* Format */
.f-1-1{
width: 100%;
padding-top: 100%;
}
.f-1-2{
width: 100%;
padding-top: calc(100% * 2);
}
.f-2-1{
width: 100%;
padding-top: calc(100% / 2);
}
.f-2-3{
width: 100%;
padding-top: calc((100% / 2) * 3);
}
.f-3-2{
width: 100%;
padding-top: calc((100% / 3) * 2);
}
.f-1-3{
width: 100%;
padding-top: calc((100% / 1) * 3);
}
.f-3-1{
width: 100%;
padding-top: calc((100% / 3) * 1);
}
.f-16-9{
width: 100%;
padding-top: calc((100% / 16) * 9);
}
.f-9-16{
width: 100%;
padding-top: calc((100% / 9) * 16);
}    

/* Display */
.d-block { display: block !important; }
.d-none { display: none !important; }
.d-flex { display: flex !important; }
.d-grid { display: grid !important; }
.d-table { display: table !important; }
.d-inline { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-inline-flex { display: inline-flex !important; }
.d-inline-table { display: inline-table !important; }

/* Justify */
.j-content-start { justify-content: start; }
.j-content-center { justify-content: center; }
.j-content-end { justify-content: end; }

.j-self-start { justify-self: start; }
.j-self-center { justify-self: center; }
.j-self-end { justify-self: end; }

/* Flex position */
.flex-wrap { flex-wrap: wrap; }
.flex-wrap-reverse { flex-wrap: wrap-reverse; }

@media (max-width: 1000px) {
/* Position */
.p-t-r { position: relative !important; }
.p-t-a { position: absolute !important; }
.p-t-f { position: fixed !important; }
.p-t-u { position: unset !important; }

/* Float */
.f-t-start { float: left !important; }
.f-t-end { float: right !important; }
.f-t-none { float: none !important; }

/* Absolut position */
.t-top-off { top: unset !important; }
.t-end-off { right: unset !important; }
.t-bottom-off { bottom: unset !important; }
.t-start-off { left: unset !important; }

.t-top { top: 0 !important; }
.t-end {
float: right !important;
right: 0 !important;
}
.t-bottom { bottom: 0 !important; }
.t-start {
float: left !important;
left: 0 !important;
}

/* Center element */
.t-center-off {
position: absolute;
margin: 0 !important;
top: 0% !important;
left: 0% !important;
transform: none !important;
}
.t-c-h-off {
margin-top: 0 !important;
margin-bottom: 0 !important;
top: 0% !important;
transform: none !important;
}
.t-c-w-off {
margin-left: 0 !important;
margin-right: 0 !important;
left: 0% !important;
transform: none !important;
}

.t-center {
position: absolute;
margin: 0 !important;
top: 50% !important;
left: 50% !important;
transform: translate(-50%,-50%) !important;
}
.t-c-h {
margin-top: 0 !important;
margin-bottom: 0 !important;
top: 50% !important;
transform: translateY(-50%) !important;
}
.t-c-w {
margin-left: 0 !important;
margin-right: 0 !important;
left: 50% !important;
transform: translateX(-50%) !important;
}

/* Format */
.f-t-1-1{
width: 100%;
padding-top: 100%;
}
.f-t-1-2{
width: 100%;
padding-top: calc(100% * 2);
}
.f-t-2-1{
width: 100%;
padding-top: calc(100% / 2);
}
.f-t-2-3{
width: 100%;
padding-top: calc((100% / 2) * 3);
}
.f-t-3-2{
width: 100%;
padding-top: calc((100% / 3) * 2);
}
.f-t-1-3{
width: 100%;
padding-top: calc((100% / 1) * 3);
}
.f-t-3-1{
width: 100%;
padding-top: calc((100% / 3) * 1);
}
.f-t-16-9{
width: 100%;
padding-top: calc((100% / 16) * 9);
}
.f-t-9-16{
width: 100%;
padding-top: calc((100% / 9) * 16);
}

/* Justify */
.j-t-content-start { justify-content: start; }
.j-t-content-center { justify-content: center; }
.j-t-content-end { justify-content: end; }

.j-t-self-start { justify-self: start; }
.j-t-self-center { justify-self: center; }
.j-t-self-end { justify-self: end; }

/* Flex position */
.flex-t-wrap { flex-wrap: wrap; }
.flex-t-wrap-reverse { flex-wrap: wrap-reverse; }

}

@media (max-width: 768px) {
/* Position */
.p-p-r { position: relative !important; }
.p-p-a { position: absolute !important; }
.p-p-f { position: fixed !important; }
.p-p-u { position: unset !important; }

/* Float */
.f-p-start { float: left !important; }
.f-p-end { float: right !important; }
.f-p-none { float: none !important; }

/* Absolut position */
.p-top-off { top: unset !important; }
.p-end-off { right: unset !important; }
.p-bottom-off { bottom: unset !important; }
.p-start-off { left: unset !important; }

.p-top { top: 0 !important; }
.p-end {
float: right !important;
right: 0 !important;
}
.p-bottom { bottom: 0 !important; }
.p-start {
float: left !important;
left: 0 !important;
}

/* Center element */
.p-center-off {
position: absolute;
margin: 0 !important;
top: 0% !important;
left: 0% !important;
transform: translate(-0%,-0%) !important;
}
.p-c-h-off {
margin-top: 0 !important;
margin-bottom: 0 !important;
top: 0% !important;
transform: translateY(0%) !important;
}
.p-c-w-off {
margin-left: 0 !important;
margin-right: 0 !important;
left: 0% !important;
transform: translateX(-0%) !important;
}

.p-center {
position: absolute;
margin: 0 !important;
top: 50% !important;
left: 50% !important;
transform: translate(-50%,-50%) !important;
}
.p-c-h{
margin-top: 0 !important;
margin-bottom: 0 !important;
top: 50% !important;
transform: translateY(-50%) !important;
}
.p-c-w{
margin-left: 0 !important;
margin-right: 0 !important;
left: 50% !important;
transform: translateX(-50%) !important;
}

/* Format */
.f-p-1-1{
width: 100%;
padding-top: 100%;
}
.f-p-1-2{
width: 100%;
padding-top: calc(100% * 2);
}
.f-p-2-1{
width: 100%;
padding-top: calc(100% / 2);
}
.f-p-2-3{
width: 100%;
padding-top: calc((100% / 2) * 3);
}
.f-p-3-2{
width: 100%;
padding-top: calc((100% / 3) * 2);
}
.f-p-1-3{
width: 100%;
padding-top: calc((100% / 1) * 3);
}
.f-p-3-1{
width: 100%;
padding-top: calc((100% / 3) * 1);
}
.f-p-16-9{
width: 100%;
padding-top: calc((100% / 16) * 9);
}
.f-p-9-16{
width: 100%;
padding-top: calc((100% / 9) * 16);
}   

/* Justify */
.j-p-content-start { justify-content: start; }
.j-p-content-center { justify-content: center; }
.j-p-content-end { justify-content: end; }

.j-p-self-start { justify-self: start; }
.j-p-self-center { justify-self: center; }
.j-p-self-end { justify-self: end; }

/* Flex position */
.flex-p-wrap { flex-wrap: wrap; }
.flex-p-wrap-reverse { flex-wrap: wrap-reverse; }

}