.m-0 {
  margin: calc(var(--spacer) * 0) !important;
}
.mh-0 {
  margin: calc(var(--spacer) * 0) 0 !important;
}
.mw-0 {
  margin: 0 calc(var(--spacer) * 0) !important;
}
.mt-0 {
  margin-top: calc(var(--spacer) * 0) !important;
}
.mr-0 {
  margin-right: calc(var(--spacer) * 0) !important;
}
.mb-0 {
  margin-bottom: calc(var(--spacer) * 0) !important;
}
.ml-0 {
  margin-left: calc(var(--spacer) * 0) !important;
}
.m-1 {
  margin: calc(var(--spacer) * 1) !important;
}
.mh-1 {
  margin: calc(var(--spacer) * 1) 0 !important;
}
.mw-1 {
  margin: 0 calc(var(--spacer) * 1) !important;
}
.mt-1 {
  margin-top: calc(var(--spacer) * 1) !important;
}
.mr-1 {
  margin-right: calc(var(--spacer) * 1) !important;
}
.mb-1 {
  margin-bottom: calc(var(--spacer) * 1) !important;
}
.ml-1 {
  margin-left: calc(var(--spacer) * 1) !important;
}
.m-2 {
  margin: calc(var(--spacer) * 2) !important;
}
.mh-2 {
  margin: calc(var(--spacer) * 2) 0 !important;
}
.mw-2 {
  margin: 0 calc(var(--spacer) * 2) !important;
}
.mt-2 {
  margin-top: calc(var(--spacer) * 2) !important;
}
.mr-2 {
  margin-right: calc(var(--spacer) * 2) !important;
}
.mb-2 {
  margin-bottom: calc(var(--spacer) * 2) !important;
}
.ml-2 {
  margin-left: calc(var(--spacer) * 2) !important;
}
.m-3 {
  margin: calc(var(--spacer) * 3) !important;
}
.mh-3 {
  margin: calc(var(--spacer) * 3) 0 !important;
}
.mw-3 {
  margin: 0 calc(var(--spacer) * 3) !important;
}
.mt-3 {
  margin-top: calc(var(--spacer) * 3) !important;
}
.mr-3 {
  margin-right: calc(var(--spacer) * 3) !important;
}
.mb-3 {
  margin-bottom: calc(var(--spacer) * 3) !important;
}
.ml-3 {
  margin-left: calc(var(--spacer) * 3) !important;
}
.m-4 {
  margin: calc(var(--spacer) * 4) !important;
}
.mh-4 {
  margin: calc(var(--spacer) * 4) 0 !important;
}
.mw-4 {
  margin: 0 calc(var(--spacer) * 4) !important;
}
.mt-4 {
  margin-top: calc(var(--spacer) * 4) !important;
}
.mr-4 {
  margin-right: calc(var(--spacer) * 4) !important;
}
.mb-4 {
  margin-bottom: calc(var(--spacer) * 4) !important;
}
.ml-4 {
  margin-left: calc(var(--spacer) * 4) !important;
}
.m-5 {
  margin: calc(var(--spacer) * 5) !important;
}
.mh-5 {
  margin: calc(var(--spacer) * 5) 0 !important;
}
.mw-5 {
  margin: 0 calc(var(--spacer) * 5) !important;
}
.mt-5 {
  margin-top: calc(var(--spacer) * 5) !important;
}
.mr-5 {
  margin-right: calc(var(--spacer) * 5) !important;
}
.mb-5 {
  margin-bottom: calc(var(--spacer) * 5) !important;
}
.ml-5 {
  margin-left: calc(var(--spacer) * 5) !important;
}
.m-6 {
  margin: calc(var(--spacer) * 6) !important;
}
.mh-6 {
  margin: calc(var(--spacer) * 6) 0 !important;
}
.mw-6 {
  margin: 0 calc(var(--spacer) * 6) !important;
}
.mt-6 {
  margin-top: calc(var(--spacer) * 6) !important;
}
.mr-6 {
  margin-right: calc(var(--spacer) * 6) !important;
}
.mb-6 {
  margin-bottom: calc(var(--spacer) * 6) !important;
}
.ml-6 {
  margin-left: calc(var(--spacer) * 6) !important;
}
.m-7 {
  margin: calc(var(--spacer) * 7) !important;
}
.mh-7 {
  margin: calc(var(--spacer) * 7) 0 !important;
}
.mw-7 {
  margin: 0 calc(var(--spacer) * 7) !important;
}
.mt-7 {
  margin-top: calc(var(--spacer) * 7) !important;
}
.mr-7 {
  margin-right: calc(var(--spacer) * 7) !important;
}
.mb-7 {
  margin-bottom: calc(var(--spacer) * 7) !important;
}
.ml-7 {
  margin-left: calc(var(--spacer) * 7) !important;
}
.m-8 {
  margin: calc(var(--spacer) * 8) !important;
}
.mh-8 {
  margin: calc(var(--spacer) * 8) 0 !important;
}
.mw-8 {
  margin: 0 calc(var(--spacer) * 8) !important;
}
.mt-8 {
  margin-top: calc(var(--spacer) * 8) !important;
}
.mr-8 {
  margin-right: calc(var(--spacer) * 8) !important;
}
.mb-8 {
  margin-bottom: calc(var(--spacer) * 8) !important;
}
.ml-8 {
  margin-left: calc(var(--spacer) * 8) !important;
}
.m-9 {
  margin: calc(var(--spacer) * 9) !important;
}
.mh-9 {
  margin: calc(var(--spacer) * 9) 0 !important;
}
.mw-9 {
  margin: 0 calc(var(--spacer) * 9) !important;
}
.mt-9 {
  margin-top: calc(var(--spacer) * 9) !important;
}
.mr-9 {
  margin-right: calc(var(--spacer) * 9) !important;
}
.mb-9 {
  margin-bottom: calc(var(--spacer) * 9) !important;
}
.ml-9 {
  margin-left: calc(var(--spacer) * 9) !important;
}
.m-10 {
  margin: calc(var(--spacer) * 10) !important;
}
.mh-10 {
  margin: calc(var(--spacer) * 10) 0 !important;
}
.mw-10 {
  margin: 0 calc(var(--spacer) * 10) !important;
}
.mt-10 {
  margin-top: calc(var(--spacer) * 10) !important;
}
.mr-10 {
  margin-right: calc(var(--spacer) * 10) !important;
}
.mb-10 {
  margin-bottom: calc(var(--spacer) * 10) !important;
}
.ml-10 {
  margin-left: calc(var(--spacer) * 10) !important;
}
.m-11 {
  margin: calc(var(--spacer) * 11) !important;
}
.mh-11 {
  margin: calc(var(--spacer) * 11) 0 !important;
}
.mw-11 {
  margin: 0 calc(var(--spacer) * 11) !important;
}
.mt-11 {
  margin-top: calc(var(--spacer) * 11) !important;
}
.mr-11 {
  margin-right: calc(var(--spacer) * 11) !important;
}
.mb-11 {
  margin-bottom: calc(var(--spacer) * 11) !important;
}
.ml-11 {
  margin-left: calc(var(--spacer) * 11) !important;
}
.m-12 {
  margin: calc(var(--spacer) * 12) !important;
}
.mh-12 {
  margin: calc(var(--spacer) * 12) 0 !important;
}
.mw-12 {
  margin: 0 calc(var(--spacer) * 12) !important;
}
.mt-12 {
  margin-top: calc(var(--spacer) * 12) !important;
}
.mr-12 {
  margin-right: calc(var(--spacer) * 12) !important;
}
.mb-12 {
  margin-bottom: calc(var(--spacer) * 12) !important;
}
.ml-12 {
  margin-left: calc(var(--spacer) * 12) !important;
}
@media (max-width: 1000px) {
  .m-t-0 {
    margin: calc(var(--spacer) * 0) !important;
  }
  .mh-t-0 {
    margin: calc(var(--spacer) * 0) 0 !important;
  }
  .mw-t-0 {
    margin: 0 calc(var(--spacer) * 0) !important;
  }
  .mt-t-0 {
    margin-top: calc(var(--spacer) * 0) !important;
  }
  .mr-t-0 {
    margin-right: calc(var(--spacer) * 0) !important;
  }
  .mb-t-0 {
    margin-bottom: calc(var(--spacer) * 0) !important;
  }
  .ml-t-0 {
    margin-left: calc(var(--spacer) * 0) !important;
  }
  .m-t-1 {
    margin: calc(var(--spacer) * 1) !important;
  }
  .mh-t-1 {
    margin: calc(var(--spacer) * 1) 0 !important;
  }
  .mw-t-1 {
    margin: 0 calc(var(--spacer) * 1) !important;
  }
  .mt-t-1 {
    margin-top: calc(var(--spacer) * 1) !important;
  }
  .mr-t-1 {
    margin-right: calc(var(--spacer) * 1) !important;
  }
  .mb-t-1 {
    margin-bottom: calc(var(--spacer) * 1) !important;
  }
  .ml-t-1 {
    margin-left: calc(var(--spacer) * 1) !important;
  }
  .m-t-2 {
    margin: calc(var(--spacer) * 2) !important;
  }
  .mh-t-2 {
    margin: calc(var(--spacer) * 2) 0 !important;
  }
  .mw-t-2 {
    margin: 0 calc(var(--spacer) * 2) !important;
  }
  .mt-t-2 {
    margin-top: calc(var(--spacer) * 2) !important;
  }
  .mr-t-2 {
    margin-right: calc(var(--spacer) * 2) !important;
  }
  .mb-t-2 {
    margin-bottom: calc(var(--spacer) * 2) !important;
  }
  .ml-t-2 {
    margin-left: calc(var(--spacer) * 2) !important;
  }
  .m-t-3 {
    margin: calc(var(--spacer) * 3) !important;
  }
  .mh-t-3 {
    margin: calc(var(--spacer) * 3) 0 !important;
  }
  .mw-t-3 {
    margin: 0 calc(var(--spacer) * 3) !important;
  }
  .mt-t-3 {
    margin-top: calc(var(--spacer) * 3) !important;
  }
  .mr-t-3 {
    margin-right: calc(var(--spacer) * 3) !important;
  }
  .mb-t-3 {
    margin-bottom: calc(var(--spacer) * 3) !important;
  }
  .ml-t-3 {
    margin-left: calc(var(--spacer) * 3) !important;
  }
  .m-t-4 {
    margin: calc(var(--spacer) * 4) !important;
  }
  .mh-t-4 {
    margin: calc(var(--spacer) * 4) 0 !important;
  }
  .mw-t-4 {
    margin: 0 calc(var(--spacer) * 4) !important;
  }
  .mt-t-4 {
    margin-top: calc(var(--spacer) * 4) !important;
  }
  .mr-t-4 {
    margin-right: calc(var(--spacer) * 4) !important;
  }
  .mb-t-4 {
    margin-bottom: calc(var(--spacer) * 4) !important;
  }
  .ml-t-4 {
    margin-left: calc(var(--spacer) * 4) !important;
  }
  .m-t-5 {
    margin: calc(var(--spacer) * 5) !important;
  }
  .mh-t-5 {
    margin: calc(var(--spacer) * 5) 0 !important;
  }
  .mw-t-5 {
    margin: 0 calc(var(--spacer) * 5) !important;
  }
  .mt-t-5 {
    margin-top: calc(var(--spacer) * 5) !important;
  }
  .mr-t-5 {
    margin-right: calc(var(--spacer) * 5) !important;
  }
  .mb-t-5 {
    margin-bottom: calc(var(--spacer) * 5) !important;
  }
  .ml-t-5 {
    margin-left: calc(var(--spacer) * 5) !important;
  }
  .m-t-6 {
    margin: calc(var(--spacer) * 6) !important;
  }
  .mh-t-6 {
    margin: calc(var(--spacer) * 6) 0 !important;
  }
  .mw-t-6 {
    margin: 0 calc(var(--spacer) * 6) !important;
  }
  .mt-t-6 {
    margin-top: calc(var(--spacer) * 6) !important;
  }
  .mr-t-6 {
    margin-right: calc(var(--spacer) * 6) !important;
  }
  .mb-t-6 {
    margin-bottom: calc(var(--spacer) * 6) !important;
  }
  .ml-t-6 {
    margin-left: calc(var(--spacer) * 6) !important;
  }
  .m-t-7 {
    margin: calc(var(--spacer) * 7) !important;
  }
  .mh-t-7 {
    margin: calc(var(--spacer) * 7) 0 !important;
  }
  .mw-t-7 {
    margin: 0 calc(var(--spacer) * 7) !important;
  }
  .mt-t-7 {
    margin-top: calc(var(--spacer) * 7) !important;
  }
  .mr-t-7 {
    margin-right: calc(var(--spacer) * 7) !important;
  }
  .mb-t-7 {
    margin-bottom: calc(var(--spacer) * 7) !important;
  }
  .ml-t-7 {
    margin-left: calc(var(--spacer) * 7) !important;
  }
  .m-t-8 {
    margin: calc(var(--spacer) * 8) !important;
  }
  .mh-t-8 {
    margin: calc(var(--spacer) * 8) 0 !important;
  }
  .mw-t-8 {
    margin: 0 calc(var(--spacer) * 8) !important;
  }
  .mt-t-8 {
    margin-top: calc(var(--spacer) * 8) !important;
  }
  .mr-t-8 {
    margin-right: calc(var(--spacer) * 8) !important;
  }
  .mb-t-8 {
    margin-bottom: calc(var(--spacer) * 8) !important;
  }
  .ml-t-8 {
    margin-left: calc(var(--spacer) * 8) !important;
  }
  .m-t-9 {
    margin: calc(var(--spacer) * 9) !important;
  }
  .mh-t-9 {
    margin: calc(var(--spacer) * 9) 0 !important;
  }
  .mw-t-9 {
    margin: 0 calc(var(--spacer) * 9) !important;
  }
  .mt-t-9 {
    margin-top: calc(var(--spacer) * 9) !important;
  }
  .mr-t-9 {
    margin-right: calc(var(--spacer) * 9) !important;
  }
  .mb-t-9 {
    margin-bottom: calc(var(--spacer) * 9) !important;
  }
  .ml-t-9 {
    margin-left: calc(var(--spacer) * 9) !important;
  }
  .m-t-10 {
    margin: calc(var(--spacer) * 10) !important;
  }
  .mh-t-10 {
    margin: calc(var(--spacer) * 10) 0 !important;
  }
  .mw-t-10 {
    margin: 0 calc(var(--spacer) * 10) !important;
  }
  .mt-t-10 {
    margin-top: calc(var(--spacer) * 10) !important;
  }
  .mr-t-10 {
    margin-right: calc(var(--spacer) * 10) !important;
  }
  .mb-t-10 {
    margin-bottom: calc(var(--spacer) * 10) !important;
  }
  .ml-t-10 {
    margin-left: calc(var(--spacer) * 10) !important;
  }
  .m-t-11 {
    margin: calc(var(--spacer) * 11) !important;
  }
  .mh-t-11 {
    margin: calc(var(--spacer) * 11) 0 !important;
  }
  .mw-t-11 {
    margin: 0 calc(var(--spacer) * 11) !important;
  }
  .mt-t-11 {
    margin-top: calc(var(--spacer) * 11) !important;
  }
  .mr-t-11 {
    margin-right: calc(var(--spacer) * 11) !important;
  }
  .mb-t-11 {
    margin-bottom: calc(var(--spacer) * 11) !important;
  }
  .ml-t-11 {
    margin-left: calc(var(--spacer) * 11) !important;
  }
  .m-t-12 {
    margin: calc(var(--spacer) * 12) !important;
  }
  .mh-t-12 {
    margin: calc(var(--spacer) * 12) 0 !important;
  }
  .mw-t-12 {
    margin: 0 calc(var(--spacer) * 12) !important;
  }
  .mt-t-12 {
    margin-top: calc(var(--spacer) * 12) !important;
  }
  .mr-t-12 {
    margin-right: calc(var(--spacer) * 12) !important;
  }
  .mb-t-12 {
    margin-bottom: calc(var(--spacer) * 12) !important;
  }
  .ml-t-12 {
    margin-left: calc(var(--spacer) * 12) !important;
  }
}
@media (max-width: 768px) {
  .m-p-0 {
    margin: calc(var(--spacer) * 0) !important;
  }
  .mh-p-0 {
    margin: calc(var(--spacer) * 0) 0 !important;
  }
  .mw-p-0 {
    margin: 0 calc(var(--spacer) * 0) !important;
  }
  .mt-p-0 {
    margin-top: calc(var(--spacer) * 0) !important;
  }
  .mr-p-0 {
    margin-right: calc(var(--spacer) * 0) !important;
  }
  .mb-p-0 {
    margin-bottom: calc(var(--spacer) * 0) !important;
  }
  .ml-p-0 {
    margin-left: calc(var(--spacer) * 0) !important;
  }
  .m-p-1 {
    margin: calc(var(--spacer) * 1) !important;
  }
  .mh-p-1 {
    margin: calc(var(--spacer) * 1) 0 !important;
  }
  .mw-p-1 {
    margin: 0 calc(var(--spacer) * 1) !important;
  }
  .mt-p-1 {
    margin-top: calc(var(--spacer) * 1) !important;
  }
  .mr-p-1 {
    margin-right: calc(var(--spacer) * 1) !important;
  }
  .mb-p-1 {
    margin-bottom: calc(var(--spacer) * 1) !important;
  }
  .ml-p-1 {
    margin-left: calc(var(--spacer) * 1) !important;
  }
  .m-p-2 {
    margin: calc(var(--spacer) * 2) !important;
  }
  .mh-p-2 {
    margin: calc(var(--spacer) * 2) 0 !important;
  }
  .mw-p-2 {
    margin: 0 calc(var(--spacer) * 2) !important;
  }
  .mt-p-2 {
    margin-top: calc(var(--spacer) * 2) !important;
  }
  .mr-p-2 {
    margin-right: calc(var(--spacer) * 2) !important;
  }
  .mb-p-2 {
    margin-bottom: calc(var(--spacer) * 2) !important;
  }
  .ml-p-2 {
    margin-left: calc(var(--spacer) * 2) !important;
  }
  .m-p-3 {
    margin: calc(var(--spacer) * 3) !important;
  }
  .mh-p-3 {
    margin: calc(var(--spacer) * 3) 0 !important;
  }
  .mw-p-3 {
    margin: 0 calc(var(--spacer) * 3) !important;
  }
  .mt-p-3 {
    margin-top: calc(var(--spacer) * 3) !important;
  }
  .mr-p-3 {
    margin-right: calc(var(--spacer) * 3) !important;
  }
  .mb-p-3 {
    margin-bottom: calc(var(--spacer) * 3) !important;
  }
  .ml-p-3 {
    margin-left: calc(var(--spacer) * 3) !important;
  }
  .m-p-4 {
    margin: calc(var(--spacer) * 4) !important;
  }
  .mh-p-4 {
    margin: calc(var(--spacer) * 4) 0 !important;
  }
  .mw-p-4 {
    margin: 0 calc(var(--spacer) * 4) !important;
  }
  .mt-p-4 {
    margin-top: calc(var(--spacer) * 4) !important;
  }
  .mr-p-4 {
    margin-right: calc(var(--spacer) * 4) !important;
  }
  .mb-p-4 {
    margin-bottom: calc(var(--spacer) * 4) !important;
  }
  .ml-p-4 {
    margin-left: calc(var(--spacer) * 4) !important;
  }
  .m-p-5 {
    margin: calc(var(--spacer) * 5) !important;
  }
  .mh-p-5 {
    margin: calc(var(--spacer) * 5) 0 !important;
  }
  .mw-p-5 {
    margin: 0 calc(var(--spacer) * 5) !important;
  }
  .mt-p-5 {
    margin-top: calc(var(--spacer) * 5) !important;
  }
  .mr-p-5 {
    margin-right: calc(var(--spacer) * 5) !important;
  }
  .mb-p-5 {
    margin-bottom: calc(var(--spacer) * 5) !important;
  }
  .ml-p-5 {
    margin-left: calc(var(--spacer) * 5) !important;
  }
  .m-p-6 {
    margin: calc(var(--spacer) * 6) !important;
  }
  .mh-p-6 {
    margin: calc(var(--spacer) * 6) 0 !important;
  }
  .mw-p-6 {
    margin: 0 calc(var(--spacer) * 6) !important;
  }
  .mt-p-6 {
    margin-top: calc(var(--spacer) * 6) !important;
  }
  .mr-p-6 {
    margin-right: calc(var(--spacer) * 6) !important;
  }
  .mb-p-6 {
    margin-bottom: calc(var(--spacer) * 6) !important;
  }
  .ml-p-6 {
    margin-left: calc(var(--spacer) * 6) !important;
  }
  .m-p-7 {
    margin: calc(var(--spacer) * 7) !important;
  }
  .mh-p-7 {
    margin: calc(var(--spacer) * 7) 0 !important;
  }
  .mw-p-7 {
    margin: 0 calc(var(--spacer) * 7) !important;
  }
  .mt-p-7 {
    margin-top: calc(var(--spacer) * 7) !important;
  }
  .mr-p-7 {
    margin-right: calc(var(--spacer) * 7) !important;
  }
  .mb-p-7 {
    margin-bottom: calc(var(--spacer) * 7) !important;
  }
  .ml-p-7 {
    margin-left: calc(var(--spacer) * 7) !important;
  }
  .m-p-8 {
    margin: calc(var(--spacer) * 8) !important;
  }
  .mh-p-8 {
    margin: calc(var(--spacer) * 8) 0 !important;
  }
  .mw-p-8 {
    margin: 0 calc(var(--spacer) * 8) !important;
  }
  .mt-p-8 {
    margin-top: calc(var(--spacer) * 8) !important;
  }
  .mr-p-8 {
    margin-right: calc(var(--spacer) * 8) !important;
  }
  .mb-p-8 {
    margin-bottom: calc(var(--spacer) * 8) !important;
  }
  .ml-p-8 {
    margin-left: calc(var(--spacer) * 8) !important;
  }
  .m-p-9 {
    margin: calc(var(--spacer) * 9) !important;
  }
  .mh-p-9 {
    margin: calc(var(--spacer) * 9) 0 !important;
  }
  .mw-p-9 {
    margin: 0 calc(var(--spacer) * 9) !important;
  }
  .mt-p-9 {
    margin-top: calc(var(--spacer) * 9) !important;
  }
  .mr-p-9 {
    margin-right: calc(var(--spacer) * 9) !important;
  }
  .mb-p-9 {
    margin-bottom: calc(var(--spacer) * 9) !important;
  }
  .ml-p-9 {
    margin-left: calc(var(--spacer) * 9) !important;
  }
  .m-p-10 {
    margin: calc(var(--spacer) * 10) !important;
  }
  .mh-p-10 {
    margin: calc(var(--spacer) * 10) 0 !important;
  }
  .mw-p-10 {
    margin: 0 calc(var(--spacer) * 10) !important;
  }
  .mt-p-10 {
    margin-top: calc(var(--spacer) * 10) !important;
  }
  .mr-p-10 {
    margin-right: calc(var(--spacer) * 10) !important;
  }
  .mb-p-10 {
    margin-bottom: calc(var(--spacer) * 10) !important;
  }
  .ml-p-10 {
    margin-left: calc(var(--spacer) * 10) !important;
  }
  .m-p-11 {
    margin: calc(var(--spacer) * 11) !important;
  }
  .mh-p-11 {
    margin: calc(var(--spacer) * 11) 0 !important;
  }
  .mw-p-11 {
    margin: 0 calc(var(--spacer) * 11) !important;
  }
  .mt-p-11 {
    margin-top: calc(var(--spacer) * 11) !important;
  }
  .mr-p-11 {
    margin-right: calc(var(--spacer) * 11) !important;
  }
  .mb-p-11 {
    margin-bottom: calc(var(--spacer) * 11) !important;
  }
  .ml-p-11 {
    margin-left: calc(var(--spacer) * 11) !important;
  }
  .m-p-12 {
    margin: calc(var(--spacer) * 12) !important;
  }
  .mh-p-12 {
    margin: calc(var(--spacer) * 12) 0 !important;
  }
  .mw-p-12 {
    margin: 0 calc(var(--spacer) * 12) !important;
  }
  .mt-p-12 {
    margin-top: calc(var(--spacer) * 12) !important;
  }
  .mr-p-12 {
    margin-right: calc(var(--spacer) * 12) !important;
  }
  .mb-p-12 {
    margin-bottom: calc(var(--spacer) * 12) !important;
  }
  .ml-p-12 {
    margin-left: calc(var(--spacer) * 12) !important;
  }
}
