.wi-box,
.wi-dropdown-box {
width: 100%;
padding: 16px;
box-sizing: border-box;
background: #ffffff;
border-radius: var(--button-border-radius);
border-color: var(--dropdown-border-color);
border-style: solid;
border-width: var(--dropdown-border-width);
}
.wi-dropdown-box .wi-dropdown-title {
width: 100%;
height: var(--line-height);
line-height: var(--line-height);
font-size: var(--font-size);
transition: .3s;
color: var(--modal-tx);
cursor: pointer;
-webkit-touch-callout: none !important;
-webkit-user-select: none !important;
-khtml-user-select: none !important;
-moz-user-select: none !important;
-ms-user-select: none !important;
user-select: none !important; 
}
.wi-dropdown-box .wi-dropdown-title i {
position: relative;
float: right;
height: var(--line-height);
line-height: var(--line-height);
font-size: var(--font-size);
opacity: .7;
color: var(--modal-tx);
transition: .3s;
}
.wi-dropdown-box .wi-dropdown-title i:hover {
opacity: 1;
}

.wi-dropdown-box .wi-dropdown-content {
width: 100%;
max-height: 0px;
display: flex;
flex-direction: column;
box-sizing: border-box;
overflow: hidden;
transition-delay: 1s, 250ms;
transition: .3s;
}
.wi-dropdown-box.wi-show > .wi-dropdown-title {
margin-bottom: 16px;
}
.wi-dropdown-box.wi-show > .wi-dropdown-content {
max-height: 1000vh;
overflow: visible;
}

.wi-dropdown-btn.wi-show:first-child:after {
content: "";
position: absolute;
float: left;
width: 100%;
left: 0;
height: calc(100% + var(--spacer));
}
.wi-dropdown-btn .wi-dropdown-list {
position: absolute;
float: right;
right: 0;
top: 100%;
min-width: 160px;
max-height: 0;
border-radius: var(--button-border-radius);
padding: 0px 0px;
background: var(--dropdown-bg);
border-color: var(--dropdown-border-color);
border-style: solid;
border-top-width: 0px;
border-right-width: var(--dropdown-border-width);
border-bottom-width: 0px;
border-left-width: var(--dropdown-border-width);
overflow: hidden;
transition: .3s;
z-index: 101;
}
.wi-dropdown-btn.wi-show .wi-dropdown-list {
max-height: 100vh;
padding: calc(var(--spacer) * 2);
margin-top: calc(var(--spacer) * 1);
border-top-width: var(--dropdown-border-width);
border-bottom-width: var(--dropdown-border-width);
overflow: visible;
}
.wi-dropdown-btn .wi-dropdown-list .wi-dropdown-item {
position: relative;
float: left;
width: 100%;
font-size: var(--text-font-size);
line-height: var(--text-line-height);
padding: calc(var(--spacer) * 2) calc(var(--spacer) * 2);
border-radius: calc(var(--button-border-radius) / 2);
text-decoration: none;
color: var(--dropdown-tx);
background: transparent;
transition: .3s;
box-sizing: border-box;
}
.wi-dropdown-btn .wi-dropdown-list .wi-dropdown-item:hover {
background: var(--dropdown-bg-hover);
}
.wi-dropdown-btn .wi-dropdown-list .wi-dropdown-divider {
position: relative;
float: left;
width: 100%;
border-top: 1px solid var(--dropdown-border-color);
margin: calc(var(--spacer) * 2) 0;
}