.w-0 {
  width: 0% !important;
}
.max-w-0 {
  max-width: 0% !important;
}
.min-vw-0,
.min-w-0 {
  min-width: 0 !important;
}
.h-0 {
  height: 0% !important;
}
.max-h-0 {
  max-height: 0% !important;
}
.min-h-0,
.min-vh-0 {
  min-height: 0 !important;
}
.vw-0 {
  width: 0 !important;
}
.max-vw-0 {
  max-width: 0 !important;
}
.vh-0 {
  height: 0 !important;
}
.max-vh-0 {
  max-height: 0 !important;
}
.w-5 {
  width: 5% !important;
}
.max-w-5 {
  max-width: 5% !important;
}
.min-w-5 {
  min-width: 5% !important;
}
.h-5 {
  height: 5% !important;
}
.max-h-5 {
  max-height: 5% !important;
}
.min-h-5 {
  min-height: 5% !important;
}
.vw-5 {
  width: 5vw !important;
}
.max-vw-5 {
  max-width: 5vw !important;
}
.min-vw-5 {
  min-width: 5vw !important;
}
.vh-5 {
  height: 5vh !important;
}
.max-vh-5 {
  max-height: 5vh !important;
}
.min-vh-5 {
  min-height: 5vh !important;
}
.w-10 {
  width: 10% !important;
}
.max-w-10 {
  max-width: 10% !important;
}
.min-w-10 {
  min-width: 10% !important;
}
.h-10 {
  height: 10% !important;
}
.max-h-10 {
  max-height: 10% !important;
}
.min-h-10 {
  min-height: 10% !important;
}
.vw-10 {
  width: 10vw !important;
}
.max-vw-10 {
  max-width: 10vw !important;
}
.min-vw-10 {
  min-width: 10vw !important;
}
.vh-10 {
  height: 10vh !important;
}
.max-vh-10 {
  max-height: 10vh !important;
}
.min-vh-10 {
  min-height: 10vh !important;
}
.w-15 {
  width: 15% !important;
}
.max-w-15 {
  max-width: 15% !important;
}
.min-w-15 {
  min-width: 15% !important;
}
.h-15 {
  height: 15% !important;
}
.max-h-15 {
  max-height: 15% !important;
}
.min-h-15 {
  min-height: 15% !important;
}
.vw-15 {
  width: 15vw !important;
}
.max-vw-15 {
  max-width: 15vw !important;
}
.min-vw-15 {
  min-width: 15vw !important;
}
.vh-15 {
  height: 15vh !important;
}
.max-vh-15 {
  max-height: 15vh !important;
}
.min-vh-15 {
  min-height: 15vh !important;
}
.w-20 {
  width: 20% !important;
}
.max-w-20 {
  max-width: 20% !important;
}
.min-w-20 {
  min-width: 20% !important;
}
.h-20 {
  height: 20% !important;
}
.max-h-20 {
  max-height: 20% !important;
}
.min-h-20 {
  min-height: 20% !important;
}
.vw-20 {
  width: 20vw !important;
}
.max-vw-20 {
  max-width: 20vw !important;
}
.min-vw-20 {
  min-width: 20vw !important;
}
.vh-20 {
  height: 20vh !important;
}
.max-vh-20 {
  max-height: 20vh !important;
}
.min-vh-20 {
  min-height: 20vh !important;
}
.w-25 {
  width: 25% !important;
}
.max-w-25 {
  max-width: 25% !important;
}
.min-w-25 {
  min-width: 25% !important;
}
.h-25 {
  height: 25% !important;
}
.max-h-25 {
  max-height: 25% !important;
}
.min-h-25 {
  min-height: 25% !important;
}
.vw-25 {
  width: 25vw !important;
}
.max-vw-25 {
  max-width: 25vw !important;
}
.min-vw-25 {
  min-width: 25vw !important;
}
.vh-25 {
  height: 25vh !important;
}
.max-vh-25 {
  max-height: 25vh !important;
}
.min-vh-25 {
  min-height: 25vh !important;
}
.w-30 {
  width: 30% !important;
}
.max-w-30 {
  max-width: 30% !important;
}
.min-w-30 {
  min-width: 30% !important;
}
.h-30 {
  height: 30% !important;
}
.max-h-30 {
  max-height: 30% !important;
}
.min-h-30 {
  min-height: 30% !important;
}
.vw-30 {
  width: 30vw !important;
}
.max-vw-30 {
  max-width: 30vw !important;
}
.min-vw-30 {
  min-width: 30vw !important;
}
.vh-30 {
  height: 30vh !important;
}
.max-vh-30 {
  max-height: 30vh !important;
}
.min-vh-30 {
  min-height: 30vh !important;
}
.w-35 {
  width: 35% !important;
}
.max-w-35 {
  max-width: 35% !important;
}
.min-w-35 {
  min-width: 35% !important;
}
.h-35 {
  height: 35% !important;
}
.max-h-35 {
  max-height: 35% !important;
}
.min-h-35 {
  min-height: 35% !important;
}
.vw-35 {
  width: 35vw !important;
}
.max-vw-35 {
  max-width: 35vw !important;
}
.min-vw-35 {
  min-width: 35vw !important;
}
.vh-35 {
  height: 35vh !important;
}
.max-vh-35 {
  max-height: 35vh !important;
}
.min-vh-35 {
  min-height: 35vh !important;
}
.w-40 {
  width: 40% !important;
}
.max-w-40 {
  max-width: 40% !important;
}
.min-w-40 {
  min-width: 40% !important;
}
.h-40 {
  height: 40% !important;
}
.max-h-40 {
  max-height: 40% !important;
}
.min-h-40 {
  min-height: 40% !important;
}
.vw-40 {
  width: 40vw !important;
}
.max-vw-40 {
  max-width: 40vw !important;
}
.min-vw-40 {
  min-width: 40vw !important;
}
.vh-40 {
  height: 40vh !important;
}
.max-vh-40 {
  max-height: 40vh !important;
}
.min-vh-40 {
  min-height: 40vh !important;
}
.w-45 {
  width: 45% !important;
}
.max-w-45 {
  max-width: 45% !important;
}
.min-w-45 {
  min-width: 45% !important;
}
.h-45 {
  height: 45% !important;
}
.max-h-45 {
  max-height: 45% !important;
}
.min-h-45 {
  min-height: 45% !important;
}
.vw-45 {
  width: 45vw !important;
}
.max-vw-45 {
  max-width: 45vw !important;
}
.min-vw-45 {
  min-width: 45vw !important;
}
.vh-45 {
  height: 45vh !important;
}
.max-vh-45 {
  max-height: 45vh !important;
}
.min-vh-45 {
  min-height: 45vh !important;
}
.w-50 {
  width: 50% !important;
}
.max-w-50 {
  max-width: 50% !important;
}
.min-w-50 {
  min-width: 50% !important;
}
.h-50 {
  height: 50% !important;
}
.max-h-50 {
  max-height: 50% !important;
}
.min-h-50 {
  min-height: 50% !important;
}
.vw-50 {
  width: 50vw !important;
}
.max-vw-50 {
  max-width: 50vw !important;
}
.min-vw-50 {
  min-width: 50vw !important;
}
.vh-50 {
  height: 50vh !important;
}
.max-vh-50 {
  max-height: 50vh !important;
}
.min-vh-50 {
  min-height: 50vh !important;
}
.w-55 {
  width: 55% !important;
}
.max-w-55 {
  max-width: 55% !important;
}
.min-w-55 {
  min-width: 55% !important;
}
.h-55 {
  height: 55% !important;
}
.max-h-55 {
  max-height: 55% !important;
}
.min-h-55 {
  min-height: 55% !important;
}
.vw-55 {
  width: 55vw !important;
}
.max-vw-55 {
  max-width: 55vw !important;
}
.min-vw-55 {
  min-width: 55vw !important;
}
.vh-55 {
  height: 55vh !important;
}
.max-vh-55 {
  max-height: 55vh !important;
}
.min-vh-55 {
  min-height: 55vh !important;
}
.w-60 {
  width: 60% !important;
}
.max-w-60 {
  max-width: 60% !important;
}
.min-w-60 {
  min-width: 60% !important;
}
.h-60 {
  height: 60% !important;
}
.max-h-60 {
  max-height: 60% !important;
}
.min-h-60 {
  min-height: 60% !important;
}
.vw-60 {
  width: 60vw !important;
}
.max-vw-60 {
  max-width: 60vw !important;
}
.min-vw-60 {
  min-width: 60vw !important;
}
.vh-60 {
  height: 60vh !important;
}
.max-vh-60 {
  max-height: 60vh !important;
}
.min-vh-60 {
  min-height: 60vh !important;
}
.w-65 {
  width: 65% !important;
}
.max-w-65 {
  max-width: 65% !important;
}
.min-w-65 {
  min-width: 65% !important;
}
.h-65 {
  height: 65% !important;
}
.max-h-65 {
  max-height: 65% !important;
}
.min-h-65 {
  min-height: 65% !important;
}
.vw-65 {
  width: 65vw !important;
}
.max-vw-65 {
  max-width: 65vw !important;
}
.min-vw-65 {
  min-width: 65vw !important;
}
.vh-65 {
  height: 65vh !important;
}
.max-vh-65 {
  max-height: 65vh !important;
}
.min-vh-65 {
  min-height: 65vh !important;
}
.w-70 {
  width: 70% !important;
}
.max-w-70 {
  max-width: 70% !important;
}
.min-w-70 {
  min-width: 70% !important;
}
.h-70 {
  height: 70% !important;
}
.max-h-70 {
  max-height: 70% !important;
}
.min-h-70 {
  min-height: 70% !important;
}
.vw-70 {
  width: 70vw !important;
}
.max-vw-70 {
  max-width: 70vw !important;
}
.min-vw-70 {
  min-width: 70vw !important;
}
.vh-70 {
  height: 70vh !important;
}
.max-vh-70 {
  max-height: 70vh !important;
}
.min-vh-70 {
  min-height: 70vh !important;
}
.w-75 {
  width: 75% !important;
}
.max-w-75 {
  max-width: 75% !important;
}
.min-w-75 {
  min-width: 75% !important;
}
.h-75 {
  height: 75% !important;
}
.max-h-75 {
  max-height: 75% !important;
}
.min-h-75 {
  min-height: 75% !important;
}
.vw-75 {
  width: 75vw !important;
}
.max-vw-75 {
  max-width: 75vw !important;
}
.min-vw-75 {
  min-width: 75vw !important;
}
.vh-75 {
  height: 75vh !important;
}
.max-vh-75 {
  max-height: 75vh !important;
}
.min-vh-75 {
  min-height: 75vh !important;
}
.w-80 {
  width: 80% !important;
}
.max-w-80 {
  max-width: 80% !important;
}
.min-w-80 {
  min-width: 80% !important;
}
.h-80 {
  height: 80% !important;
}
.max-h-80 {
  max-height: 80% !important;
}
.min-h-80 {
  min-height: 80% !important;
}
.vw-80 {
  width: 80vw !important;
}
.max-vw-80 {
  max-width: 80vw !important;
}
.min-vw-80 {
  min-width: 80vw !important;
}
.vh-80 {
  height: 80vh !important;
}
.max-vh-80 {
  max-height: 80vh !important;
}
.min-vh-80 {
  min-height: 80vh !important;
}
.w-85 {
  width: 85% !important;
}
.max-w-85 {
  max-width: 85% !important;
}
.min-w-85 {
  min-width: 85% !important;
}
.h-85 {
  height: 85% !important;
}
.max-h-85 {
  max-height: 85% !important;
}
.min-h-85 {
  min-height: 85% !important;
}
.vw-85 {
  width: 85vw !important;
}
.max-vw-85 {
  max-width: 85vw !important;
}
.min-vw-85 {
  min-width: 85vw !important;
}
.vh-85 {
  height: 85vh !important;
}
.max-vh-85 {
  max-height: 85vh !important;
}
.min-vh-85 {
  min-height: 85vh !important;
}
.w-90 {
  width: 90% !important;
}
.max-w-90 {
  max-width: 90% !important;
}
.min-w-90 {
  min-width: 90% !important;
}
.h-90 {
  height: 90% !important;
}
.max-h-90 {
  max-height: 90% !important;
}
.min-h-90 {
  min-height: 90% !important;
}
.vw-90 {
  width: 90vw !important;
}
.max-vw-90 {
  max-width: 90vw !important;
}
.min-vw-90 {
  min-width: 90vw !important;
}
.vh-90 {
  height: 90vh !important;
}
.max-vh-90 {
  max-height: 90vh !important;
}
.min-vh-90 {
  min-height: 90vh !important;
}
.w-95 {
  width: 95% !important;
}
.max-w-95 {
  max-width: 95% !important;
}
.min-w-95 {
  min-width: 95% !important;
}
.h-95 {
  height: 95% !important;
}
.max-h-95 {
  max-height: 95% !important;
}
.min-h-95 {
  min-height: 95% !important;
}
.vw-95 {
  width: 95vw !important;
}
.max-vw-95 {
  max-width: 95vw !important;
}
.min-vw-95 {
  min-width: 95vw !important;
}
.vh-95 {
  height: 95vh !important;
}
.max-vh-95 {
  max-height: 95vh !important;
}
.min-vh-95 {
  min-height: 95vh !important;
}
.w-100 {
  width: 100% !important;
}
.max-w-100 {
  max-width: 100% !important;
}
.min-w-100 {
  min-width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.max-h-100 {
  max-height: 100% !important;
}
.min-h-100 {
  min-height: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.max-vw-100 {
  max-width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.max-vh-100 {
  max-height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.w-105 {
  width: 105% !important;
}
.max-w-105 {
  max-width: 105% !important;
}
.min-w-105 {
  min-width: 105% !important;
}
.h-105 {
  height: 105% !important;
}
.max-h-105 {
  max-height: 105% !important;
}
.min-h-105 {
  min-height: 105% !important;
}
.vw-105 {
  width: 105vw !important;
}
.max-vw-105 {
  max-width: 105vw !important;
}
.min-vw-105 {
  min-width: 105vw !important;
}
.vh-105 {
  height: 105vh !important;
}
.max-vh-105 {
  max-height: 105vh !important;
}
.min-vh-105 {
  min-height: 105vh !important;
}
.w-110 {
  width: 110% !important;
}
.max-w-110 {
  max-width: 110% !important;
}
.min-w-110 {
  min-width: 110% !important;
}
.h-110 {
  height: 110% !important;
}
.max-h-110 {
  max-height: 110% !important;
}
.min-h-110 {
  min-height: 110% !important;
}
.vw-110 {
  width: 110vw !important;
}
.max-vw-110 {
  max-width: 110vw !important;
}
.min-vw-110 {
  min-width: 110vw !important;
}
.vh-110 {
  height: 110vh !important;
}
.max-vh-110 {
  max-height: 110vh !important;
}
.min-vh-110 {
  min-height: 110vh !important;
}
.w-115 {
  width: 115% !important;
}
.max-w-115 {
  max-width: 115% !important;
}
.min-w-115 {
  min-width: 115% !important;
}
.h-115 {
  height: 115% !important;
}
.max-h-115 {
  max-height: 115% !important;
}
.min-h-115 {
  min-height: 115% !important;
}
.vw-115 {
  width: 115vw !important;
}
.max-vw-115 {
  max-width: 115vw !important;
}
.min-vw-115 {
  min-width: 115vw !important;
}
.vh-115 {
  height: 115vh !important;
}
.max-vh-115 {
  max-height: 115vh !important;
}
.min-vh-115 {
  min-height: 115vh !important;
}
.w-120 {
  width: 120% !important;
}
.max-w-120 {
  max-width: 120% !important;
}
.min-w-120 {
  min-width: 120% !important;
}
.h-120 {
  height: 120% !important;
}
.max-h-120 {
  max-height: 120% !important;
}
.min-h-120 {
  min-height: 120% !important;
}
.vw-120 {
  width: 120vw !important;
}
.max-vw-120 {
  max-width: 120vw !important;
}
.min-vw-120 {
  min-width: 120vw !important;
}
.vh-120 {
  height: 120vh !important;
}
.max-vh-120 {
  max-height: 120vh !important;
}
.min-vh-120 {
  min-height: 120vh !important;
}
.w-125 {
  width: 125% !important;
}
.max-w-125 {
  max-width: 125% !important;
}
.min-w-125 {
  min-width: 125% !important;
}
.h-125 {
  height: 125% !important;
}
.max-h-125 {
  max-height: 125% !important;
}
.min-h-125 {
  min-height: 125% !important;
}
.vw-125 {
  width: 125vw !important;
}
.max-vw-125 {
  max-width: 125vw !important;
}
.min-vw-125 {
  min-width: 125vw !important;
}
.vh-125 {
  height: 125vh !important;
}
.max-vh-125 {
  max-height: 125vh !important;
}
.min-vh-125 {
  min-height: 125vh !important;
}
.w-130 {
  width: 130% !important;
}
.max-w-130 {
  max-width: 130% !important;
}
.min-w-130 {
  min-width: 130% !important;
}
.h-130 {
  height: 130% !important;
}
.max-h-130 {
  max-height: 130% !important;
}
.min-h-130 {
  min-height: 130% !important;
}
.vw-130 {
  width: 130vw !important;
}
.max-vw-130 {
  max-width: 130vw !important;
}
.min-vw-130 {
  min-width: 130vw !important;
}
.vh-130 {
  height: 130vh !important;
}
.max-vh-130 {
  max-height: 130vh !important;
}
.min-vh-130 {
  min-height: 130vh !important;
}
.w-135 {
  width: 135% !important;
}
.max-w-135 {
  max-width: 135% !important;
}
.min-w-135 {
  min-width: 135% !important;
}
.h-135 {
  height: 135% !important;
}
.max-h-135 {
  max-height: 135% !important;
}
.min-h-135 {
  min-height: 135% !important;
}
.vw-135 {
  width: 135vw !important;
}
.max-vw-135 {
  max-width: 135vw !important;
}
.min-vw-135 {
  min-width: 135vw !important;
}
.vh-135 {
  height: 135vh !important;
}
.max-vh-135 {
  max-height: 135vh !important;
}
.min-vh-135 {
  min-height: 135vh !important;
}
.w-140 {
  width: 140% !important;
}
.max-w-140 {
  max-width: 140% !important;
}
.min-w-140 {
  min-width: 140% !important;
}
.h-140 {
  height: 140% !important;
}
.max-h-140 {
  max-height: 140% !important;
}
.min-h-140 {
  min-height: 140% !important;
}
.vw-140 {
  width: 140vw !important;
}
.max-vw-140 {
  max-width: 140vw !important;
}
.min-vw-140 {
  min-width: 140vw !important;
}
.vh-140 {
  height: 140vh !important;
}
.max-vh-140 {
  max-height: 140vh !important;
}
.min-vh-140 {
  min-height: 140vh !important;
}
.w-145 {
  width: 145% !important;
}
.max-w-145 {
  max-width: 145% !important;
}
.min-w-145 {
  min-width: 145% !important;
}
.h-145 {
  height: 145% !important;
}
.max-h-145 {
  max-height: 145% !important;
}
.min-h-145 {
  min-height: 145% !important;
}
.vw-145 {
  width: 145vw !important;
}
.max-vw-145 {
  max-width: 145vw !important;
}
.min-vw-145 {
  min-width: 145vw !important;
}
.vh-145 {
  height: 145vh !important;
}
.max-vh-145 {
  max-height: 145vh !important;
}
.min-vh-145 {
  min-height: 145vh !important;
}
.w-150 {
  width: 150% !important;
}
.max-w-150 {
  max-width: 150% !important;
}
.min-w-150 {
  min-width: 150% !important;
}
.h-150 {
  height: 150% !important;
}
.max-h-150 {
  max-height: 150% !important;
}
.min-h-150 {
  min-height: 150% !important;
}
.vw-150 {
  width: 150vw !important;
}
.max-vw-150 {
  max-width: 150vw !important;
}
.min-vw-150 {
  min-width: 150vw !important;
}
.vh-150 {
  height: 150vh !important;
}
.max-vh-150 {
  max-height: 150vh !important;
}
.min-vh-150 {
  min-height: 150vh !important;
}
.w-auto {
  width: auto !important;
}
.w-fit {
  width: fit-content !important;
}
.h-auto {
  height: auto !important;
}
.h-fit {
  height: fit-content !important;
}
@media (max-width: 1000px) {
  .w-t-0 {
    width: 0% !important;
  }
  .max-w-t-0 {
    max-width: 0% !important;
  }
  .min-vw-t-0,
  .min-w-t-0 {
    min-width: 0 !important;
  }
  .h-t-0 {
    height: 0% !important;
  }
  .max-h-t-0 {
    max-height: 0% !important;
  }
  .min-h-t-0,
  .min-vh-t-0 {
    min-height: 0 !important;
  }
  .vw-t-0 {
    width: 0 !important;
  }
  .max-vw-t-0 {
    max-width: 0 !important;
  }
  .vh-t-0 {
    height: 0 !important;
  }
  .max-vh-t-0 {
    max-height: 0 !important;
  }
  .w-t-5 {
    width: 5% !important;
  }
  .max-w-t-5 {
    max-width: 5% !important;
  }
  .min-w-t-5 {
    min-width: 5% !important;
  }
  .h-t-5 {
    height: 5% !important;
  }
  .max-h-t-5 {
    max-height: 5% !important;
  }
  .min-h-t-5 {
    min-height: 5% !important;
  }
  .vw-t-5 {
    width: 5vw !important;
  }
  .max-vw-t-5 {
    max-width: 5vw !important;
  }
  .min-vw-t-5 {
    min-width: 5vw !important;
  }
  .vh-t-5 {
    height: 5vh !important;
  }
  .max-vh-t-5 {
    max-height: 5vh !important;
  }
  .min-vh-t-5 {
    min-height: 5vh !important;
  }
  .w-t-10 {
    width: 10% !important;
  }
  .max-w-t-10 {
    max-width: 10% !important;
  }
  .min-w-t-10 {
    min-width: 10% !important;
  }
  .h-t-10 {
    height: 10% !important;
  }
  .max-h-t-10 {
    max-height: 10% !important;
  }
  .min-h-t-10 {
    min-height: 10% !important;
  }
  .vw-t-10 {
    width: 10vw !important;
  }
  .max-vw-t-10 {
    max-width: 10vw !important;
  }
  .min-vw-t-10 {
    min-width: 10vw !important;
  }
  .vh-t-10 {
    height: 10vh !important;
  }
  .max-vh-t-10 {
    max-height: 10vh !important;
  }
  .min-vh-t-10 {
    min-height: 10vh !important;
  }
  .w-t-15 {
    width: 15% !important;
  }
  .max-w-t-15 {
    max-width: 15% !important;
  }
  .min-w-t-15 {
    min-width: 15% !important;
  }
  .h-t-15 {
    height: 15% !important;
  }
  .max-h-t-15 {
    max-height: 15% !important;
  }
  .min-h-t-15 {
    min-height: 15% !important;
  }
  .vw-t-15 {
    width: 15vw !important;
  }
  .max-vw-t-15 {
    max-width: 15vw !important;
  }
  .min-vw-t-15 {
    min-width: 15vw !important;
  }
  .vh-t-15 {
    height: 15vh !important;
  }
  .max-vh-t-15 {
    max-height: 15vh !important;
  }
  .min-vh-t-15 {
    min-height: 15vh !important;
  }
  .w-t-20 {
    width: 20% !important;
  }
  .max-w-t-20 {
    max-width: 20% !important;
  }
  .min-w-t-20 {
    min-width: 20% !important;
  }
  .h-t-20 {
    height: 20% !important;
  }
  .max-h-t-20 {
    max-height: 20% !important;
  }
  .min-h-t-20 {
    min-height: 20% !important;
  }
  .vw-t-20 {
    width: 20vw !important;
  }
  .max-vw-t-20 {
    max-width: 20vw !important;
  }
  .min-vw-t-20 {
    min-width: 20vw !important;
  }
  .vh-t-20 {
    height: 20vh !important;
  }
  .max-vh-t-20 {
    max-height: 20vh !important;
  }
  .min-vh-t-20 {
    min-height: 20vh !important;
  }
  .w-t-25 {
    width: 25% !important;
  }
  .max-w-t-25 {
    max-width: 25% !important;
  }
  .min-w-t-25 {
    min-width: 25% !important;
  }
  .h-t-25 {
    height: 25% !important;
  }
  .max-h-t-25 {
    max-height: 25% !important;
  }
  .min-h-t-25 {
    min-height: 25% !important;
  }
  .vw-t-25 {
    width: 25vw !important;
  }
  .max-vw-t-25 {
    max-width: 25vw !important;
  }
  .min-vw-t-25 {
    min-width: 25vw !important;
  }
  .vh-t-25 {
    height: 25vh !important;
  }
  .max-vh-t-25 {
    max-height: 25vh !important;
  }
  .min-vh-t-25 {
    min-height: 25vh !important;
  }
  .w-t-30 {
    width: 30% !important;
  }
  .max-w-t-30 {
    max-width: 30% !important;
  }
  .min-w-t-30 {
    min-width: 30% !important;
  }
  .h-t-30 {
    height: 30% !important;
  }
  .max-h-t-30 {
    max-height: 30% !important;
  }
  .min-h-t-30 {
    min-height: 30% !important;
  }
  .vw-t-30 {
    width: 30vw !important;
  }
  .max-vw-t-30 {
    max-width: 30vw !important;
  }
  .min-vw-t-30 {
    min-width: 30vw !important;
  }
  .vh-t-30 {
    height: 30vh !important;
  }
  .max-vh-t-30 {
    max-height: 30vh !important;
  }
  .min-vh-t-30 {
    min-height: 30vh !important;
  }
  .w-t-35 {
    width: 35% !important;
  }
  .max-w-t-35 {
    max-width: 35% !important;
  }
  .min-w-t-35 {
    min-width: 35% !important;
  }
  .h-t-35 {
    height: 35% !important;
  }
  .max-h-t-35 {
    max-height: 35% !important;
  }
  .min-h-t-35 {
    min-height: 35% !important;
  }
  .vw-t-35 {
    width: 35vw !important;
  }
  .max-vw-t-35 {
    max-width: 35vw !important;
  }
  .min-vw-t-35 {
    min-width: 35vw !important;
  }
  .vh-t-35 {
    height: 35vh !important;
  }
  .max-vh-t-35 {
    max-height: 35vh !important;
  }
  .min-vh-t-35 {
    min-height: 35vh !important;
  }
  .w-t-40 {
    width: 40% !important;
  }
  .max-w-t-40 {
    max-width: 40% !important;
  }
  .min-w-t-40 {
    min-width: 40% !important;
  }
  .h-t-40 {
    height: 40% !important;
  }
  .max-h-t-40 {
    max-height: 40% !important;
  }
  .min-h-t-40 {
    min-height: 40% !important;
  }
  .vw-t-40 {
    width: 40vw !important;
  }
  .max-vw-t-40 {
    max-width: 40vw !important;
  }
  .min-vw-t-40 {
    min-width: 40vw !important;
  }
  .vh-t-40 {
    height: 40vh !important;
  }
  .max-vh-t-40 {
    max-height: 40vh !important;
  }
  .min-vh-t-40 {
    min-height: 40vh !important;
  }
  .w-t-45 {
    width: 45% !important;
  }
  .max-w-t-45 {
    max-width: 45% !important;
  }
  .min-w-t-45 {
    min-width: 45% !important;
  }
  .h-t-45 {
    height: 45% !important;
  }
  .max-h-t-45 {
    max-height: 45% !important;
  }
  .min-h-t-45 {
    min-height: 45% !important;
  }
  .vw-t-45 {
    width: 45vw !important;
  }
  .max-vw-t-45 {
    max-width: 45vw !important;
  }
  .min-vw-t-45 {
    min-width: 45vw !important;
  }
  .vh-t-45 {
    height: 45vh !important;
  }
  .max-vh-t-45 {
    max-height: 45vh !important;
  }
  .min-vh-t-45 {
    min-height: 45vh !important;
  }
  .w-t-50 {
    width: 50% !important;
  }
  .max-w-t-50 {
    max-width: 50% !important;
  }
  .min-w-t-50 {
    min-width: 50% !important;
  }
  .h-t-50 {
    height: 50% !important;
  }
  .max-h-t-50 {
    max-height: 50% !important;
  }
  .min-h-t-50 {
    min-height: 50% !important;
  }
  .vw-t-50 {
    width: 50vw !important;
  }
  .max-vw-t-50 {
    max-width: 50vw !important;
  }
  .min-vw-t-50 {
    min-width: 50vw !important;
  }
  .vh-t-50 {
    height: 50vh !important;
  }
  .max-vh-t-50 {
    max-height: 50vh !important;
  }
  .min-vh-t-50 {
    min-height: 50vh !important;
  }
  .w-t-55 {
    width: 55% !important;
  }
  .max-w-t-55 {
    max-width: 55% !important;
  }
  .min-w-t-55 {
    min-width: 55% !important;
  }
  .h-t-55 {
    height: 55% !important;
  }
  .max-h-t-55 {
    max-height: 55% !important;
  }
  .min-h-t-55 {
    min-height: 55% !important;
  }
  .vw-t-55 {
    width: 55vw !important;
  }
  .max-vw-t-55 {
    max-width: 55vw !important;
  }
  .min-vw-t-55 {
    min-width: 55vw !important;
  }
  .vh-t-55 {
    height: 55vh !important;
  }
  .max-vh-t-55 {
    max-height: 55vh !important;
  }
  .min-vh-t-55 {
    min-height: 55vh !important;
  }
  .w-t-60 {
    width: 60% !important;
  }
  .max-w-t-60 {
    max-width: 60% !important;
  }
  .min-w-t-60 {
    min-width: 60% !important;
  }
  .h-t-60 {
    height: 60% !important;
  }
  .max-h-t-60 {
    max-height: 60% !important;
  }
  .min-h-t-60 {
    min-height: 60% !important;
  }
  .vw-t-60 {
    width: 60vw !important;
  }
  .max-vw-t-60 {
    max-width: 60vw !important;
  }
  .min-vw-t-60 {
    min-width: 60vw !important;
  }
  .vh-t-60 {
    height: 60vh !important;
  }
  .max-vh-t-60 {
    max-height: 60vh !important;
  }
  .min-vh-t-60 {
    min-height: 60vh !important;
  }
  .w-t-65 {
    width: 65% !important;
  }
  .max-w-t-65 {
    max-width: 65% !important;
  }
  .min-w-t-65 {
    min-width: 65% !important;
  }
  .h-t-65 {
    height: 65% !important;
  }
  .max-h-t-65 {
    max-height: 65% !important;
  }
  .min-h-t-65 {
    min-height: 65% !important;
  }
  .vw-t-65 {
    width: 65vw !important;
  }
  .max-vw-t-65 {
    max-width: 65vw !important;
  }
  .min-vw-t-65 {
    min-width: 65vw !important;
  }
  .vh-t-65 {
    height: 65vh !important;
  }
  .max-vh-t-65 {
    max-height: 65vh !important;
  }
  .min-vh-t-65 {
    min-height: 65vh !important;
  }
  .w-t-70 {
    width: 70% !important;
  }
  .max-w-t-70 {
    max-width: 70% !important;
  }
  .min-w-t-70 {
    min-width: 70% !important;
  }
  .h-t-70 {
    height: 70% !important;
  }
  .max-h-t-70 {
    max-height: 70% !important;
  }
  .min-h-t-70 {
    min-height: 70% !important;
  }
  .vw-t-70 {
    width: 70vw !important;
  }
  .max-vw-t-70 {
    max-width: 70vw !important;
  }
  .min-vw-t-70 {
    min-width: 70vw !important;
  }
  .vh-t-70 {
    height: 70vh !important;
  }
  .max-vh-t-70 {
    max-height: 70vh !important;
  }
  .min-vh-t-70 {
    min-height: 70vh !important;
  }
  .w-t-75 {
    width: 75% !important;
  }
  .max-w-t-75 {
    max-width: 75% !important;
  }
  .min-w-t-75 {
    min-width: 75% !important;
  }
  .h-t-75 {
    height: 75% !important;
  }
  .max-h-t-75 {
    max-height: 75% !important;
  }
  .min-h-t-75 {
    min-height: 75% !important;
  }
  .vw-t-75 {
    width: 75vw !important;
  }
  .max-vw-t-75 {
    max-width: 75vw !important;
  }
  .min-vw-t-75 {
    min-width: 75vw !important;
  }
  .vh-t-75 {
    height: 75vh !important;
  }
  .max-vh-t-75 {
    max-height: 75vh !important;
  }
  .min-vh-t-75 {
    min-height: 75vh !important;
  }
  .w-t-80 {
    width: 80% !important;
  }
  .max-w-t-80 {
    max-width: 80% !important;
  }
  .min-w-t-80 {
    min-width: 80% !important;
  }
  .h-t-80 {
    height: 80% !important;
  }
  .max-h-t-80 {
    max-height: 80% !important;
  }
  .min-h-t-80 {
    min-height: 80% !important;
  }
  .vw-t-80 {
    width: 80vw !important;
  }
  .max-vw-t-80 {
    max-width: 80vw !important;
  }
  .min-vw-t-80 {
    min-width: 80vw !important;
  }
  .vh-t-80 {
    height: 80vh !important;
  }
  .max-vh-t-80 {
    max-height: 80vh !important;
  }
  .min-vh-t-80 {
    min-height: 80vh !important;
  }
  .w-t-85 {
    width: 85% !important;
  }
  .max-w-t-85 {
    max-width: 85% !important;
  }
  .min-w-t-85 {
    min-width: 85% !important;
  }
  .h-t-85 {
    height: 85% !important;
  }
  .max-h-t-85 {
    max-height: 85% !important;
  }
  .min-h-t-85 {
    min-height: 85% !important;
  }
  .vw-t-85 {
    width: 85vw !important;
  }
  .max-vw-t-85 {
    max-width: 85vw !important;
  }
  .min-vw-t-85 {
    min-width: 85vw !important;
  }
  .vh-t-85 {
    height: 85vh !important;
  }
  .max-vh-t-85 {
    max-height: 85vh !important;
  }
  .min-vh-t-85 {
    min-height: 85vh !important;
  }
  .w-t-90 {
    width: 90% !important;
  }
  .max-w-t-90 {
    max-width: 90% !important;
  }
  .min-w-t-90 {
    min-width: 90% !important;
  }
  .h-t-90 {
    height: 90% !important;
  }
  .max-h-t-90 {
    max-height: 90% !important;
  }
  .min-h-t-90 {
    min-height: 90% !important;
  }
  .vw-t-90 {
    width: 90vw !important;
  }
  .max-vw-t-90 {
    max-width: 90vw !important;
  }
  .min-vw-t-90 {
    min-width: 90vw !important;
  }
  .vh-t-90 {
    height: 90vh !important;
  }
  .max-vh-t-90 {
    max-height: 90vh !important;
  }
  .min-vh-t-90 {
    min-height: 90vh !important;
  }
  .w-t-95 {
    width: 95% !important;
  }
  .max-w-t-95 {
    max-width: 95% !important;
  }
  .min-w-t-95 {
    min-width: 95% !important;
  }
  .h-t-95 {
    height: 95% !important;
  }
  .max-h-t-95 {
    max-height: 95% !important;
  }
  .min-h-t-95 {
    min-height: 95% !important;
  }
  .vw-t-95 {
    width: 95vw !important;
  }
  .max-vw-t-95 {
    max-width: 95vw !important;
  }
  .min-vw-t-95 {
    min-width: 95vw !important;
  }
  .vh-t-95 {
    height: 95vh !important;
  }
  .max-vh-t-95 {
    max-height: 95vh !important;
  }
  .min-vh-t-95 {
    min-height: 95vh !important;
  }
  .w-t-100 {
    width: 100% !important;
  }
  .max-w-t-100 {
    max-width: 100% !important;
  }
  .min-w-t-100 {
    min-width: 100% !important;
  }
  .h-t-100 {
    height: 100% !important;
  }
  .max-h-t-100 {
    max-height: 100% !important;
  }
  .min-h-t-100 {
    min-height: 100% !important;
  }
  .vw-t-100 {
    width: 100vw !important;
  }
  .max-vw-t-100 {
    max-width: 100vw !important;
  }
  .min-vw-t-100 {
    min-width: 100vw !important;
  }
  .vh-t-100 {
    height: 100vh !important;
  }
  .max-vh-t-100 {
    max-height: 100vh !important;
  }
  .min-vh-t-100 {
    min-height: 100vh !important;
  }
  .w-t-105 {
    width: 105% !important;
  }
  .max-w-t-105 {
    max-width: 105% !important;
  }
  .min-w-t-105 {
    min-width: 105% !important;
  }
  .h-t-105 {
    height: 105% !important;
  }
  .max-h-t-105 {
    max-height: 105% !important;
  }
  .min-h-t-105 {
    min-height: 105% !important;
  }
  .vw-t-105 {
    width: 105vw !important;
  }
  .max-vw-t-105 {
    max-width: 105vw !important;
  }
  .min-vw-t-105 {
    min-width: 105vw !important;
  }
  .vh-t-105 {
    height: 105vh !important;
  }
  .max-vh-t-105 {
    max-height: 105vh !important;
  }
  .min-vh-t-105 {
    min-height: 105vh !important;
  }
  .w-t-110 {
    width: 110% !important;
  }
  .max-w-t-110 {
    max-width: 110% !important;
  }
  .min-w-t-110 {
    min-width: 110% !important;
  }
  .h-t-110 {
    height: 110% !important;
  }
  .max-h-t-110 {
    max-height: 110% !important;
  }
  .min-h-t-110 {
    min-height: 110% !important;
  }
  .vw-t-110 {
    width: 110vw !important;
  }
  .max-vw-t-110 {
    max-width: 110vw !important;
  }
  .min-vw-t-110 {
    min-width: 110vw !important;
  }
  .vh-t-110 {
    height: 110vh !important;
  }
  .max-vh-t-110 {
    max-height: 110vh !important;
  }
  .min-vh-t-110 {
    min-height: 110vh !important;
  }
  .w-t-115 {
    width: 115% !important;
  }
  .max-w-t-115 {
    max-width: 115% !important;
  }
  .min-w-t-115 {
    min-width: 115% !important;
  }
  .h-t-115 {
    height: 115% !important;
  }
  .max-h-t-115 {
    max-height: 115% !important;
  }
  .min-h-t-115 {
    min-height: 115% !important;
  }
  .vw-t-115 {
    width: 115vw !important;
  }
  .max-vw-t-115 {
    max-width: 115vw !important;
  }
  .min-vw-t-115 {
    min-width: 115vw !important;
  }
  .vh-t-115 {
    height: 115vh !important;
  }
  .max-vh-t-115 {
    max-height: 115vh !important;
  }
  .min-vh-t-115 {
    min-height: 115vh !important;
  }
  .w-t-120 {
    width: 120% !important;
  }
  .max-w-t-120 {
    max-width: 120% !important;
  }
  .min-w-t-120 {
    min-width: 120% !important;
  }
  .h-t-120 {
    height: 120% !important;
  }
  .max-h-t-120 {
    max-height: 120% !important;
  }
  .min-h-t-120 {
    min-height: 120% !important;
  }
  .vw-t-120 {
    width: 120vw !important;
  }
  .max-vw-t-120 {
    max-width: 120vw !important;
  }
  .min-vw-t-120 {
    min-width: 120vw !important;
  }
  .vh-t-120 {
    height: 120vh !important;
  }
  .max-vh-t-120 {
    max-height: 120vh !important;
  }
  .min-vh-t-120 {
    min-height: 120vh !important;
  }
  .w-t-125 {
    width: 125% !important;
  }
  .max-w-t-125 {
    max-width: 125% !important;
  }
  .min-w-t-125 {
    min-width: 125% !important;
  }
  .h-t-125 {
    height: 125% !important;
  }
  .max-h-t-125 {
    max-height: 125% !important;
  }
  .min-h-t-125 {
    min-height: 125% !important;
  }
  .vw-t-125 {
    width: 125vw !important;
  }
  .max-vw-t-125 {
    max-width: 125vw !important;
  }
  .min-vw-t-125 {
    min-width: 125vw !important;
  }
  .vh-t-125 {
    height: 125vh !important;
  }
  .max-vh-t-125 {
    max-height: 125vh !important;
  }
  .min-vh-t-125 {
    min-height: 125vh !important;
  }
  .w-t-130 {
    width: 130% !important;
  }
  .max-w-t-130 {
    max-width: 130% !important;
  }
  .min-w-t-130 {
    min-width: 130% !important;
  }
  .h-t-130 {
    height: 130% !important;
  }
  .max-h-t-130 {
    max-height: 130% !important;
  }
  .min-h-t-130 {
    min-height: 130% !important;
  }
  .vw-t-130 {
    width: 130vw !important;
  }
  .max-vw-t-130 {
    max-width: 130vw !important;
  }
  .min-vw-t-130 {
    min-width: 130vw !important;
  }
  .vh-t-130 {
    height: 130vh !important;
  }
  .max-vh-t-130 {
    max-height: 130vh !important;
  }
  .min-vh-t-130 {
    min-height: 130vh !important;
  }
  .w-t-135 {
    width: 135% !important;
  }
  .max-w-t-135 {
    max-width: 135% !important;
  }
  .min-w-t-135 {
    min-width: 135% !important;
  }
  .h-t-135 {
    height: 135% !important;
  }
  .max-h-t-135 {
    max-height: 135% !important;
  }
  .min-h-t-135 {
    min-height: 135% !important;
  }
  .vw-t-135 {
    width: 135vw !important;
  }
  .max-vw-t-135 {
    max-width: 135vw !important;
  }
  .min-vw-t-135 {
    min-width: 135vw !important;
  }
  .vh-t-135 {
    height: 135vh !important;
  }
  .max-vh-t-135 {
    max-height: 135vh !important;
  }
  .min-vh-t-135 {
    min-height: 135vh !important;
  }
  .w-t-140 {
    width: 140% !important;
  }
  .max-w-t-140 {
    max-width: 140% !important;
  }
  .min-w-t-140 {
    min-width: 140% !important;
  }
  .h-t-140 {
    height: 140% !important;
  }
  .max-h-t-140 {
    max-height: 140% !important;
  }
  .min-h-t-140 {
    min-height: 140% !important;
  }
  .vw-t-140 {
    width: 140vw !important;
  }
  .max-vw-t-140 {
    max-width: 140vw !important;
  }
  .min-vw-t-140 {
    min-width: 140vw !important;
  }
  .vh-t-140 {
    height: 140vh !important;
  }
  .max-vh-t-140 {
    max-height: 140vh !important;
  }
  .min-vh-t-140 {
    min-height: 140vh !important;
  }
  .w-t-145 {
    width: 145% !important;
  }
  .max-w-t-145 {
    max-width: 145% !important;
  }
  .min-w-t-145 {
    min-width: 145% !important;
  }
  .h-t-145 {
    height: 145% !important;
  }
  .max-h-t-145 {
    max-height: 145% !important;
  }
  .min-h-t-145 {
    min-height: 145% !important;
  }
  .vw-t-145 {
    width: 145vw !important;
  }
  .max-vw-t-145 {
    max-width: 145vw !important;
  }
  .min-vw-t-145 {
    min-width: 145vw !important;
  }
  .vh-t-145 {
    height: 145vh !important;
  }
  .max-vh-t-145 {
    max-height: 145vh !important;
  }
  .min-vh-t-145 {
    min-height: 145vh !important;
  }
  .w-t-150 {
    width: 150% !important;
  }
  .max-w-t-150 {
    max-width: 150% !important;
  }
  .min-w-t-150 {
    min-width: 150% !important;
  }
  .h-t-150 {
    height: 150% !important;
  }
  .max-h-t-150 {
    max-height: 150% !important;
  }
  .min-h-t-150 {
    min-height: 150% !important;
  }
  .vw-t-150 {
    width: 150vw !important;
  }
  .max-vw-t-150 {
    max-width: 150vw !important;
  }
  .min-vw-t-150 {
    min-width: 150vw !important;
  }
  .vh-t-150 {
    height: 150vh !important;
  }
  .max-vh-t-150 {
    max-height: 150vh !important;
  }
  .min-vh-t-150 {
    min-height: 150vh !important;
  }
  .w-t-auto {
    width: auto !important;
  }
  .w-t-fit {
    width: fit-content !important;
  }
  .h-t-auto {
    height: auto !important;
  }
  .h-t-fit {
    height: fit-content !important;
  }
}
@media (max-width: 768px) {
  .w-p-0 {
    width: 0% !important;
  }
  .max-w-p-0 {
    max-width: 0% !important;
  }
  .min-vw-p-0,
  .min-w-p-0 {
    min-width: 0 !important;
  }
  .h-p-0 {
    height: 0% !important;
  }
  .max-h-p-0 {
    max-height: 0% !important;
  }
  .min-h-p-0,
  .min-vh-p-0 {
    min-height: 0 !important;
  }
  .vw-p-0 {
    width: 0 !important;
  }
  .max-vw-p-0 {
    max-width: 0 !important;
  }
  .vh-p-0 {
    height: 0 !important;
  }
  .max-vh-p-0 {
    max-height: 0 !important;
  }
  .w-p-5 {
    width: 5% !important;
  }
  .max-w-p-5 {
    max-width: 5% !important;
  }
  .min-w-p-5 {
    min-width: 5% !important;
  }
  .h-p-5 {
    height: 5% !important;
  }
  .max-h-p-5 {
    max-height: 5% !important;
  }
  .min-h-p-5 {
    min-height: 5% !important;
  }
  .vw-p-5 {
    width: 5vw !important;
  }
  .max-vw-p-5 {
    max-width: 5vw !important;
  }
  .min-vw-p-5 {
    min-width: 5vw !important;
  }
  .vh-p-5 {
    height: 5vh !important;
  }
  .max-vh-p-5 {
    max-height: 5vh !important;
  }
  .min-vh-p-5 {
    min-height: 5vh !important;
  }
  .w-p-10 {
    width: 10% !important;
  }
  .max-w-p-10 {
    max-width: 10% !important;
  }
  .min-w-p-10 {
    min-width: 10% !important;
  }
  .h-p-10 {
    height: 10% !important;
  }
  .max-h-p-10 {
    max-height: 10% !important;
  }
  .min-h-p-10 {
    min-height: 10% !important;
  }
  .vw-p-10 {
    width: 10vw !important;
  }
  .max-vw-p-10 {
    max-width: 10vw !important;
  }
  .min-vw-p-10 {
    min-width: 10vw !important;
  }
  .vh-p-10 {
    height: 10vh !important;
  }
  .max-vh-p-10 {
    max-height: 10vh !important;
  }
  .min-vh-p-10 {
    min-height: 10vh !important;
  }
  .w-p-15 {
    width: 15% !important;
  }
  .max-w-p-15 {
    max-width: 15% !important;
  }
  .min-w-p-15 {
    min-width: 15% !important;
  }
  .h-p-15 {
    height: 15% !important;
  }
  .max-h-p-15 {
    max-height: 15% !important;
  }
  .min-h-p-15 {
    min-height: 15% !important;
  }
  .vw-p-15 {
    width: 15vw !important;
  }
  .max-vw-p-15 {
    max-width: 15vw !important;
  }
  .min-vw-p-15 {
    min-width: 15vw !important;
  }
  .vh-p-15 {
    height: 15vh !important;
  }
  .max-vh-p-15 {
    max-height: 15vh !important;
  }
  .min-vh-p-15 {
    min-height: 15vh !important;
  }
  .w-p-20 {
    width: 20% !important;
  }
  .max-w-p-20 {
    max-width: 20% !important;
  }
  .min-w-p-20 {
    min-width: 20% !important;
  }
  .h-p-20 {
    height: 20% !important;
  }
  .max-h-p-20 {
    max-height: 20% !important;
  }
  .min-h-p-20 {
    min-height: 20% !important;
  }
  .vw-p-20 {
    width: 20vw !important;
  }
  .max-vw-p-20 {
    max-width: 20vw !important;
  }
  .min-vw-p-20 {
    min-width: 20vw !important;
  }
  .vh-p-20 {
    height: 20vh !important;
  }
  .max-vh-p-20 {
    max-height: 20vh !important;
  }
  .min-vh-p-20 {
    min-height: 20vh !important;
  }
  .w-p-25 {
    width: 25% !important;
  }
  .max-w-p-25 {
    max-width: 25% !important;
  }
  .min-w-p-25 {
    min-width: 25% !important;
  }
  .h-p-25 {
    height: 25% !important;
  }
  .max-h-p-25 {
    max-height: 25% !important;
  }
  .min-h-p-25 {
    min-height: 25% !important;
  }
  .vw-p-25 {
    width: 25vw !important;
  }
  .max-vw-p-25 {
    max-width: 25vw !important;
  }
  .min-vw-p-25 {
    min-width: 25vw !important;
  }
  .vh-p-25 {
    height: 25vh !important;
  }
  .max-vh-p-25 {
    max-height: 25vh !important;
  }
  .min-vh-p-25 {
    min-height: 25vh !important;
  }
  .w-p-30 {
    width: 30% !important;
  }
  .max-w-p-30 {
    max-width: 30% !important;
  }
  .min-w-p-30 {
    min-width: 30% !important;
  }
  .h-p-30 {
    height: 30% !important;
  }
  .max-h-p-30 {
    max-height: 30% !important;
  }
  .min-h-p-30 {
    min-height: 30% !important;
  }
  .vw-p-30 {
    width: 30vw !important;
  }
  .max-vw-p-30 {
    max-width: 30vw !important;
  }
  .min-vw-p-30 {
    min-width: 30vw !important;
  }
  .vh-p-30 {
    height: 30vh !important;
  }
  .max-vh-p-30 {
    max-height: 30vh !important;
  }
  .min-vh-p-30 {
    min-height: 30vh !important;
  }
  .w-p-35 {
    width: 35% !important;
  }
  .max-w-p-35 {
    max-width: 35% !important;
  }
  .min-w-p-35 {
    min-width: 35% !important;
  }
  .h-p-35 {
    height: 35% !important;
  }
  .max-h-p-35 {
    max-height: 35% !important;
  }
  .min-h-p-35 {
    min-height: 35% !important;
  }
  .vw-p-35 {
    width: 35vw !important;
  }
  .max-vw-p-35 {
    max-width: 35vw !important;
  }
  .min-vw-p-35 {
    min-width: 35vw !important;
  }
  .vh-p-35 {
    height: 35vh !important;
  }
  .max-vh-p-35 {
    max-height: 35vh !important;
  }
  .min-vh-p-35 {
    min-height: 35vh !important;
  }
  .w-p-40 {
    width: 40% !important;
  }
  .max-w-p-40 {
    max-width: 40% !important;
  }
  .min-w-p-40 {
    min-width: 40% !important;
  }
  .h-p-40 {
    height: 40% !important;
  }
  .max-h-p-40 {
    max-height: 40% !important;
  }
  .min-h-p-40 {
    min-height: 40% !important;
  }
  .vw-p-40 {
    width: 40vw !important;
  }
  .max-vw-p-40 {
    max-width: 40vw !important;
  }
  .min-vw-p-40 {
    min-width: 40vw !important;
  }
  .vh-p-40 {
    height: 40vh !important;
  }
  .max-vh-p-40 {
    max-height: 40vh !important;
  }
  .min-vh-p-40 {
    min-height: 40vh !important;
  }
  .w-p-45 {
    width: 45% !important;
  }
  .max-w-p-45 {
    max-width: 45% !important;
  }
  .min-w-p-45 {
    min-width: 45% !important;
  }
  .h-p-45 {
    height: 45% !important;
  }
  .max-h-p-45 {
    max-height: 45% !important;
  }
  .min-h-p-45 {
    min-height: 45% !important;
  }
  .vw-p-45 {
    width: 45vw !important;
  }
  .max-vw-p-45 {
    max-width: 45vw !important;
  }
  .min-vw-p-45 {
    min-width: 45vw !important;
  }
  .vh-p-45 {
    height: 45vh !important;
  }
  .max-vh-p-45 {
    max-height: 45vh !important;
  }
  .min-vh-p-45 {
    min-height: 45vh !important;
  }
  .w-p-50 {
    width: 50% !important;
  }
  .max-w-p-50 {
    max-width: 50% !important;
  }
  .min-w-p-50 {
    min-width: 50% !important;
  }
  .h-p-50 {
    height: 50% !important;
  }
  .max-h-p-50 {
    max-height: 50% !important;
  }
  .min-h-p-50 {
    min-height: 50% !important;
  }
  .vw-p-50 {
    width: 50vw !important;
  }
  .max-vw-p-50 {
    max-width: 50vw !important;
  }
  .min-vw-p-50 {
    min-width: 50vw !important;
  }
  .vh-p-50 {
    height: 50vh !important;
  }
  .max-vh-p-50 {
    max-height: 50vh !important;
  }
  .min-vh-p-50 {
    min-height: 50vh !important;
  }
  .w-p-55 {
    width: 55% !important;
  }
  .max-w-p-55 {
    max-width: 55% !important;
  }
  .min-w-p-55 {
    min-width: 55% !important;
  }
  .h-p-55 {
    height: 55% !important;
  }
  .max-h-p-55 {
    max-height: 55% !important;
  }
  .min-h-p-55 {
    min-height: 55% !important;
  }
  .vw-p-55 {
    width: 55vw !important;
  }
  .max-vw-p-55 {
    max-width: 55vw !important;
  }
  .min-vw-p-55 {
    min-width: 55vw !important;
  }
  .vh-p-55 {
    height: 55vh !important;
  }
  .max-vh-p-55 {
    max-height: 55vh !important;
  }
  .min-vh-p-55 {
    min-height: 55vh !important;
  }
  .w-p-60 {
    width: 60% !important;
  }
  .max-w-p-60 {
    max-width: 60% !important;
  }
  .min-w-p-60 {
    min-width: 60% !important;
  }
  .h-p-60 {
    height: 60% !important;
  }
  .max-h-p-60 {
    max-height: 60% !important;
  }
  .min-h-p-60 {
    min-height: 60% !important;
  }
  .vw-p-60 {
    width: 60vw !important;
  }
  .max-vw-p-60 {
    max-width: 60vw !important;
  }
  .min-vw-p-60 {
    min-width: 60vw !important;
  }
  .vh-p-60 {
    height: 60vh !important;
  }
  .max-vh-p-60 {
    max-height: 60vh !important;
  }
  .min-vh-p-60 {
    min-height: 60vh !important;
  }
  .w-p-65 {
    width: 65% !important;
  }
  .max-w-p-65 {
    max-width: 65% !important;
  }
  .min-w-p-65 {
    min-width: 65% !important;
  }
  .h-p-65 {
    height: 65% !important;
  }
  .max-h-p-65 {
    max-height: 65% !important;
  }
  .min-h-p-65 {
    min-height: 65% !important;
  }
  .vw-p-65 {
    width: 65vw !important;
  }
  .max-vw-p-65 {
    max-width: 65vw !important;
  }
  .min-vw-p-65 {
    min-width: 65vw !important;
  }
  .vh-p-65 {
    height: 65vh !important;
  }
  .max-vh-p-65 {
    max-height: 65vh !important;
  }
  .min-vh-p-65 {
    min-height: 65vh !important;
  }
  .w-p-70 {
    width: 70% !important;
  }
  .max-w-p-70 {
    max-width: 70% !important;
  }
  .min-w-p-70 {
    min-width: 70% !important;
  }
  .h-p-70 {
    height: 70% !important;
  }
  .max-h-p-70 {
    max-height: 70% !important;
  }
  .min-h-p-70 {
    min-height: 70% !important;
  }
  .vw-p-70 {
    width: 70vw !important;
  }
  .max-vw-p-70 {
    max-width: 70vw !important;
  }
  .min-vw-p-70 {
    min-width: 70vw !important;
  }
  .vh-p-70 {
    height: 70vh !important;
  }
  .max-vh-p-70 {
    max-height: 70vh !important;
  }
  .min-vh-p-70 {
    min-height: 70vh !important;
  }
  .w-p-75 {
    width: 75% !important;
  }
  .max-w-p-75 {
    max-width: 75% !important;
  }
  .min-w-p-75 {
    min-width: 75% !important;
  }
  .h-p-75 {
    height: 75% !important;
  }
  .max-h-p-75 {
    max-height: 75% !important;
  }
  .min-h-p-75 {
    min-height: 75% !important;
  }
  .vw-p-75 {
    width: 75vw !important;
  }
  .max-vw-p-75 {
    max-width: 75vw !important;
  }
  .min-vw-p-75 {
    min-width: 75vw !important;
  }
  .vh-p-75 {
    height: 75vh !important;
  }
  .max-vh-p-75 {
    max-height: 75vh !important;
  }
  .min-vh-p-75 {
    min-height: 75vh !important;
  }
  .w-p-80 {
    width: 80% !important;
  }
  .max-w-p-80 {
    max-width: 80% !important;
  }
  .min-w-p-80 {
    min-width: 80% !important;
  }
  .h-p-80 {
    height: 80% !important;
  }
  .max-h-p-80 {
    max-height: 80% !important;
  }
  .min-h-p-80 {
    min-height: 80% !important;
  }
  .vw-p-80 {
    width: 80vw !important;
  }
  .max-vw-p-80 {
    max-width: 80vw !important;
  }
  .min-vw-p-80 {
    min-width: 80vw !important;
  }
  .vh-p-80 {
    height: 80vh !important;
  }
  .max-vh-p-80 {
    max-height: 80vh !important;
  }
  .min-vh-p-80 {
    min-height: 80vh !important;
  }
  .w-p-85 {
    width: 85% !important;
  }
  .max-w-p-85 {
    max-width: 85% !important;
  }
  .min-w-p-85 {
    min-width: 85% !important;
  }
  .h-p-85 {
    height: 85% !important;
  }
  .max-h-p-85 {
    max-height: 85% !important;
  }
  .min-h-p-85 {
    min-height: 85% !important;
  }
  .vw-p-85 {
    width: 85vw !important;
  }
  .max-vw-p-85 {
    max-width: 85vw !important;
  }
  .min-vw-p-85 {
    min-width: 85vw !important;
  }
  .vh-p-85 {
    height: 85vh !important;
  }
  .max-vh-p-85 {
    max-height: 85vh !important;
  }
  .min-vh-p-85 {
    min-height: 85vh !important;
  }
  .w-p-90 {
    width: 90% !important;
  }
  .max-w-p-90 {
    max-width: 90% !important;
  }
  .min-w-p-90 {
    min-width: 90% !important;
  }
  .h-p-90 {
    height: 90% !important;
  }
  .max-h-p-90 {
    max-height: 90% !important;
  }
  .min-h-p-90 {
    min-height: 90% !important;
  }
  .vw-p-90 {
    width: 90vw !important;
  }
  .max-vw-p-90 {
    max-width: 90vw !important;
  }
  .min-vw-p-90 {
    min-width: 90vw !important;
  }
  .vh-p-90 {
    height: 90vh !important;
  }
  .max-vh-p-90 {
    max-height: 90vh !important;
  }
  .min-vh-p-90 {
    min-height: 90vh !important;
  }
  .w-p-95 {
    width: 95% !important;
  }
  .max-w-p-95 {
    max-width: 95% !important;
  }
  .min-w-p-95 {
    min-width: 95% !important;
  }
  .h-p-95 {
    height: 95% !important;
  }
  .max-h-p-95 {
    max-height: 95% !important;
  }
  .min-h-p-95 {
    min-height: 95% !important;
  }
  .vw-p-95 {
    width: 95vw !important;
  }
  .max-vw-p-95 {
    max-width: 95vw !important;
  }
  .min-vw-p-95 {
    min-width: 95vw !important;
  }
  .vh-p-95 {
    height: 95vh !important;
  }
  .max-vh-p-95 {
    max-height: 95vh !important;
  }
  .min-vh-p-95 {
    min-height: 95vh !important;
  }
  .w-p-100 {
    width: 100% !important;
  }
  .max-w-p-100 {
    max-width: 100% !important;
  }
  .min-w-p-100 {
    min-width: 100% !important;
  }
  .h-p-100 {
    height: 100% !important;
  }
  .max-h-p-100 {
    max-height: 100% !important;
  }
  .min-h-p-100 {
    min-height: 100% !important;
  }
  .vw-p-100 {
    width: 100vw !important;
  }
  .max-vw-p-100 {
    max-width: 100vw !important;
  }
  .min-vw-p-100 {
    min-width: 100vw !important;
  }
  .vh-p-100 {
    height: 100vh !important;
  }
  .max-vh-p-100 {
    max-height: 100vh !important;
  }
  .min-vh-p-100 {
    min-height: 100vh !important;
  }
  .w-p-105 {
    width: 105% !important;
  }
  .max-w-p-105 {
    max-width: 105% !important;
  }
  .min-w-p-105 {
    min-width: 105% !important;
  }
  .h-p-105 {
    height: 105% !important;
  }
  .max-h-p-105 {
    max-height: 105% !important;
  }
  .min-h-p-105 {
    min-height: 105% !important;
  }
  .vw-p-105 {
    width: 105vw !important;
  }
  .max-vw-p-105 {
    max-width: 105vw !important;
  }
  .min-vw-p-105 {
    min-width: 105vw !important;
  }
  .vh-p-105 {
    height: 105vh !important;
  }
  .max-vh-p-105 {
    max-height: 105vh !important;
  }
  .min-vh-p-105 {
    min-height: 105vh !important;
  }
  .w-p-110 {
    width: 110% !important;
  }
  .max-w-p-110 {
    max-width: 110% !important;
  }
  .min-w-p-110 {
    min-width: 110% !important;
  }
  .h-p-110 {
    height: 110% !important;
  }
  .max-h-p-110 {
    max-height: 110% !important;
  }
  .min-h-p-110 {
    min-height: 110% !important;
  }
  .vw-p-110 {
    width: 110vw !important;
  }
  .max-vw-p-110 {
    max-width: 110vw !important;
  }
  .min-vw-p-110 {
    min-width: 110vw !important;
  }
  .vh-p-110 {
    height: 110vh !important;
  }
  .max-vh-p-110 {
    max-height: 110vh !important;
  }
  .min-vh-p-110 {
    min-height: 110vh !important;
  }
  .w-p-115 {
    width: 115% !important;
  }
  .max-w-p-115 {
    max-width: 115% !important;
  }
  .min-w-p-115 {
    min-width: 115% !important;
  }
  .h-p-115 {
    height: 115% !important;
  }
  .max-h-p-115 {
    max-height: 115% !important;
  }
  .min-h-p-115 {
    min-height: 115% !important;
  }
  .vw-p-115 {
    width: 115vw !important;
  }
  .max-vw-p-115 {
    max-width: 115vw !important;
  }
  .min-vw-p-115 {
    min-width: 115vw !important;
  }
  .vh-p-115 {
    height: 115vh !important;
  }
  .max-vh-p-115 {
    max-height: 115vh !important;
  }
  .min-vh-p-115 {
    min-height: 115vh !important;
  }
  .w-p-120 {
    width: 120% !important;
  }
  .max-w-p-120 {
    max-width: 120% !important;
  }
  .min-w-p-120 {
    min-width: 120% !important;
  }
  .h-p-120 {
    height: 120% !important;
  }
  .max-h-p-120 {
    max-height: 120% !important;
  }
  .min-h-p-120 {
    min-height: 120% !important;
  }
  .vw-p-120 {
    width: 120vw !important;
  }
  .max-vw-p-120 {
    max-width: 120vw !important;
  }
  .min-vw-p-120 {
    min-width: 120vw !important;
  }
  .vh-p-120 {
    height: 120vh !important;
  }
  .max-vh-p-120 {
    max-height: 120vh !important;
  }
  .min-vh-p-120 {
    min-height: 120vh !important;
  }
  .w-p-125 {
    width: 125% !important;
  }
  .max-w-p-125 {
    max-width: 125% !important;
  }
  .min-w-p-125 {
    min-width: 125% !important;
  }
  .h-p-125 {
    height: 125% !important;
  }
  .max-h-p-125 {
    max-height: 125% !important;
  }
  .min-h-p-125 {
    min-height: 125% !important;
  }
  .vw-p-125 {
    width: 125vw !important;
  }
  .max-vw-p-125 {
    max-width: 125vw !important;
  }
  .min-vw-p-125 {
    min-width: 125vw !important;
  }
  .vh-p-125 {
    height: 125vh !important;
  }
  .max-vh-p-125 {
    max-height: 125vh !important;
  }
  .min-vh-p-125 {
    min-height: 125vh !important;
  }
  .w-p-130 {
    width: 130% !important;
  }
  .max-w-p-130 {
    max-width: 130% !important;
  }
  .min-w-p-130 {
    min-width: 130% !important;
  }
  .h-p-130 {
    height: 130% !important;
  }
  .max-h-p-130 {
    max-height: 130% !important;
  }
  .min-h-p-130 {
    min-height: 130% !important;
  }
  .vw-p-130 {
    width: 130vw !important;
  }
  .max-vw-p-130 {
    max-width: 130vw !important;
  }
  .min-vw-p-130 {
    min-width: 130vw !important;
  }
  .vh-p-130 {
    height: 130vh !important;
  }
  .max-vh-p-130 {
    max-height: 130vh !important;
  }
  .min-vh-p-130 {
    min-height: 130vh !important;
  }
  .w-p-135 {
    width: 135% !important;
  }
  .max-w-p-135 {
    max-width: 135% !important;
  }
  .min-w-p-135 {
    min-width: 135% !important;
  }
  .h-p-135 {
    height: 135% !important;
  }
  .max-h-p-135 {
    max-height: 135% !important;
  }
  .min-h-p-135 {
    min-height: 135% !important;
  }
  .vw-p-135 {
    width: 135vw !important;
  }
  .max-vw-p-135 {
    max-width: 135vw !important;
  }
  .min-vw-p-135 {
    min-width: 135vw !important;
  }
  .vh-p-135 {
    height: 135vh !important;
  }
  .max-vh-p-135 {
    max-height: 135vh !important;
  }
  .min-vh-p-135 {
    min-height: 135vh !important;
  }
  .w-p-140 {
    width: 140% !important;
  }
  .max-w-p-140 {
    max-width: 140% !important;
  }
  .min-w-p-140 {
    min-width: 140% !important;
  }
  .h-p-140 {
    height: 140% !important;
  }
  .max-h-p-140 {
    max-height: 140% !important;
  }
  .min-h-p-140 {
    min-height: 140% !important;
  }
  .vw-p-140 {
    width: 140vw !important;
  }
  .max-vw-p-140 {
    max-width: 140vw !important;
  }
  .min-vw-p-140 {
    min-width: 140vw !important;
  }
  .vh-p-140 {
    height: 140vh !important;
  }
  .max-vh-p-140 {
    max-height: 140vh !important;
  }
  .min-vh-p-140 {
    min-height: 140vh !important;
  }
  .w-p-145 {
    width: 145% !important;
  }
  .max-w-p-145 {
    max-width: 145% !important;
  }
  .min-w-p-145 {
    min-width: 145% !important;
  }
  .h-p-145 {
    height: 145% !important;
  }
  .max-h-p-145 {
    max-height: 145% !important;
  }
  .min-h-p-145 {
    min-height: 145% !important;
  }
  .vw-p-145 {
    width: 145vw !important;
  }
  .max-vw-p-145 {
    max-width: 145vw !important;
  }
  .min-vw-p-145 {
    min-width: 145vw !important;
  }
  .vh-p-145 {
    height: 145vh !important;
  }
  .max-vh-p-145 {
    max-height: 145vh !important;
  }
  .min-vh-p-145 {
    min-height: 145vh !important;
  }
  .w-p-150 {
    width: 150% !important;
  }
  .max-w-p-150 {
    max-width: 150% !important;
  }
  .min-w-p-150 {
    min-width: 150% !important;
  }
  .h-p-150 {
    height: 150% !important;
  }
  .max-h-p-150 {
    max-height: 150% !important;
  }
  .min-h-p-150 {
    min-height: 150% !important;
  }
  .vw-p-150 {
    width: 150vw !important;
  }
  .max-vw-p-150 {
    max-width: 150vw !important;
  }
  .min-vw-p-150 {
    min-width: 150vw !important;
  }
  .vh-p-150 {
    height: 150vh !important;
  }
  .max-vh-p-150 {
    max-height: 150vh !important;
  }
  .min-vh-p-150 {
    min-height: 150vh !important;
  }
  .w-p-auto {
    width: auto !important;
  }
  .w-p-fit {
    width: fit-content !important;
  }
  .h-p-auto {
    height: auto !important;
  }
  .h-p-fit {
    height: fit-content !important;
  }
}
