.ph-0,
.pt-0 {
  padding-top: calc(var(--spacer) * 0) !important;
}
.pr-0,
.pw-0 {
  padding-right: calc(var(--spacer) * 0) !important;
}
.pb-0,
.ph-0 {
  padding-bottom: calc(var(--spacer) * 0) !important;
}
.pl-0,
.pw-0 {
  padding-left: calc(var(--spacer) * 0) !important;
}
.p-0,
.p-1,
.p-10,
.p-11,
.p-12,
.p-2,
.p-3,
.p-4,
.p-5,
.p-6,
.p-7,
.p-8,
.p-9 {
  box-sizing: border-box !important;
}
.p-0 {
  padding: calc(var(--spacer) * 0) !important;
}
.ph-1,
.pt-1 {
  padding-top: calc(var(--spacer) * 1) !important;
}
.pr-1,
.pw-1 {
  padding-right: calc(var(--spacer) * 1) !important;
}
.pb-1,
.ph-1 {
  padding-bottom: calc(var(--spacer) * 1) !important;
}
.pl-1,
.pw-1 {
  padding-left: calc(var(--spacer) * 1) !important;
}
.p-1 {
  padding: calc(var(--spacer) * 1) !important;
}
.ph-2,
.pt-2 {
  padding-top: calc(var(--spacer) * 2) !important;
}
.pr-2,
.pw-2 {
  padding-right: calc(var(--spacer) * 2) !important;
}
.pb-2,
.ph-2 {
  padding-bottom: calc(var(--spacer) * 2) !important;
}
.pl-2,
.pw-2 {
  padding-left: calc(var(--spacer) * 2) !important;
}
.p-2 {
  padding: calc(var(--spacer) * 2) !important;
}
.ph-3,
.pt-3 {
  padding-top: calc(var(--spacer) * 3) !important;
}
.pr-3,
.pw-3 {
  padding-right: calc(var(--spacer) * 3) !important;
}
.pb-3,
.ph-3 {
  padding-bottom: calc(var(--spacer) * 3) !important;
}
.pl-3,
.pw-3 {
  padding-left: calc(var(--spacer) * 3) !important;
}
.p-3 {
  padding: calc(var(--spacer) * 3) !important;
}
.ph-4,
.pt-4 {
  padding-top: calc(var(--spacer) * 4) !important;
}
.pr-4,
.pw-4 {
  padding-right: calc(var(--spacer) * 4) !important;
}
.pb-4,
.ph-4 {
  padding-bottom: calc(var(--spacer) * 4) !important;
}
.pl-4,
.pw-4 {
  padding-left: calc(var(--spacer) * 4) !important;
}
.p-4 {
  padding: calc(var(--spacer) * 4) !important;
}
.ph-5,
.pt-5 {
  padding-top: calc(var(--spacer) * 5) !important;
}
.pr-5,
.pw-5 {
  padding-right: calc(var(--spacer) * 5) !important;
}
.pb-5,
.ph-5 {
  padding-bottom: calc(var(--spacer) * 5) !important;
}
.pl-5,
.pw-5 {
  padding-left: calc(var(--spacer) * 5) !important;
}
.p-5 {
  padding: calc(var(--spacer) * 5) !important;
}
.ph-6,
.pt-6 {
  padding-top: calc(var(--spacer) * 6) !important;
}
.pr-6,
.pw-6 {
  padding-right: calc(var(--spacer) * 6) !important;
}
.pb-6,
.ph-6 {
  padding-bottom: calc(var(--spacer) * 6) !important;
}
.pl-6,
.pw-6 {
  padding-left: calc(var(--spacer) * 6) !important;
}
.p-6 {
  padding: calc(var(--spacer) * 6) !important;
}
.ph-7,
.pt-7 {
  padding-top: calc(var(--spacer) * 7) !important;
}
.pr-7,
.pw-7 {
  padding-right: calc(var(--spacer) * 7) !important;
}
.pb-7,
.ph-7 {
  padding-bottom: calc(var(--spacer) * 7) !important;
}
.pl-7,
.pw-7 {
  padding-left: calc(var(--spacer) * 7) !important;
}
.p-7 {
  padding: calc(var(--spacer) * 7) !important;
}
.ph-8,
.pt-8 {
  padding-top: calc(var(--spacer) * 8) !important;
}
.pr-8,
.pw-8 {
  padding-right: calc(var(--spacer) * 8) !important;
}
.pb-8,
.ph-8 {
  padding-bottom: calc(var(--spacer) * 8) !important;
}
.pl-8,
.pw-8 {
  padding-left: calc(var(--spacer) * 8) !important;
}
.p-8 {
  padding: calc(var(--spacer) * 8) !important;
}
.ph-9,
.pt-9 {
  padding-top: calc(var(--spacer) * 9) !important;
}
.pr-9,
.pw-9 {
  padding-right: calc(var(--spacer) * 9) !important;
}
.pb-9,
.ph-9 {
  padding-bottom: calc(var(--spacer) * 9) !important;
}
.pl-9,
.pw-9 {
  padding-left: calc(var(--spacer) * 9) !important;
}
.p-9 {
  padding: calc(var(--spacer) * 9) !important;
}
.ph-10,
.pt-10 {
  padding-top: calc(var(--spacer) * 10) !important;
}
.pr-10,
.pw-10 {
  padding-right: calc(var(--spacer) * 10) !important;
}
.pb-10,
.ph-10 {
  padding-bottom: calc(var(--spacer) * 10) !important;
}
.pl-10,
.pw-10 {
  padding-left: calc(var(--spacer) * 10) !important;
}
.p-10 {
  padding: calc(var(--spacer) * 10) !important;
}
.ph-11,
.pt-11 {
  padding-top: calc(var(--spacer) * 11) !important;
}
.pr-11,
.pw-11 {
  padding-right: calc(var(--spacer) * 11) !important;
}
.pb-11,
.ph-11 {
  padding-bottom: calc(var(--spacer) * 11) !important;
}
.pl-11,
.pw-11 {
  padding-left: calc(var(--spacer) * 11) !important;
}
.p-11 {
  padding: calc(var(--spacer) * 11) !important;
}
.ph-12,
.pt-12 {
  padding-top: calc(var(--spacer) * 12) !important;
}
.pr-12,
.pw-12 {
  padding-right: calc(var(--spacer) * 12) !important;
}
.pb-12,
.ph-12 {
  padding-bottom: calc(var(--spacer) * 12) !important;
}
.pl-12,
.pw-12 {
  padding-left: calc(var(--spacer) * 12) !important;
}
.p-12 {
  padding: calc(var(--spacer) * 12) !important;
}
@media (max-width: 1000px) {
  .ph-t-0,
  .pt-t-0 {
    padding-top: calc(var(--spacer) * 0) !important;
  }
  .pr-t-0,
  .pw-t-0 {
    padding-right: calc(var(--spacer) * 0) !important;
  }
  .pb-t-0,
  .ph-t-0 {
    padding-bottom: calc(var(--spacer) * 0) !important;
  }
  .pl-t-0,
  .pw-t-0 {
    padding-left: calc(var(--spacer) * 0) !important;
  }
  .p-t-0 {
    padding: calc(var(--spacer) * 0) !important;
  }
  .ph-t-1,
  .pt-t-1 {
    padding-top: calc(var(--spacer) * 1) !important;
  }
  .pr-t-1,
  .pw-t-1 {
    padding-right: calc(var(--spacer) * 1) !important;
  }
  .pb-t-1,
  .ph-t-1 {
    padding-bottom: calc(var(--spacer) * 1) !important;
  }
  .pl-t-1,
  .pw-t-1 {
    padding-left: calc(var(--spacer) * 1) !important;
  }
  .p-t-1 {
    padding: calc(var(--spacer) * 1) !important;
  }
  .ph-t-2,
  .pt-t-2 {
    padding-top: calc(var(--spacer) * 2) !important;
  }
  .pr-t-2,
  .pw-t-2 {
    padding-right: calc(var(--spacer) * 2) !important;
  }
  .pb-t-2,
  .ph-t-2 {
    padding-bottom: calc(var(--spacer) * 2) !important;
  }
  .pl-t-2,
  .pw-t-2 {
    padding-left: calc(var(--spacer) * 2) !important;
  }
  .p-t-2 {
    padding: calc(var(--spacer) * 2) !important;
  }
  .ph-t-3,
  .pt-t-3 {
    padding-top: calc(var(--spacer) * 3) !important;
  }
  .pr-t-3,
  .pw-t-3 {
    padding-right: calc(var(--spacer) * 3) !important;
  }
  .pb-t-3,
  .ph-t-3 {
    padding-bottom: calc(var(--spacer) * 3) !important;
  }
  .pl-t-3,
  .pw-t-3 {
    padding-left: calc(var(--spacer) * 3) !important;
  }
  .p-t-3 {
    padding: calc(var(--spacer) * 3) !important;
  }
  .ph-t-4,
  .pt-t-4 {
    padding-top: calc(var(--spacer) * 4) !important;
  }
  .pr-t-4,
  .pw-t-4 {
    padding-right: calc(var(--spacer) * 4) !important;
  }
  .pb-t-4,
  .ph-t-4 {
    padding-bottom: calc(var(--spacer) * 4) !important;
  }
  .pl-t-4,
  .pw-t-4 {
    padding-left: calc(var(--spacer) * 4) !important;
  }
  .p-t-4 {
    padding: calc(var(--spacer) * 4) !important;
  }
  .ph-t-5,
  .pt-t-5 {
    padding-top: calc(var(--spacer) * 5) !important;
  }
  .pr-t-5,
  .pw-t-5 {
    padding-right: calc(var(--spacer) * 5) !important;
  }
  .pb-t-5,
  .ph-t-5 {
    padding-bottom: calc(var(--spacer) * 5) !important;
  }
  .pl-t-5,
  .pw-t-5 {
    padding-left: calc(var(--spacer) * 5) !important;
  }
  .p-t-5 {
    padding: calc(var(--spacer) * 5) !important;
  }
  .ph-t-6,
  .pt-t-6 {
    padding-top: calc(var(--spacer) * 6) !important;
  }
  .pr-t-6,
  .pw-t-6 {
    padding-right: calc(var(--spacer) * 6) !important;
  }
  .pb-t-6,
  .ph-t-6 {
    padding-bottom: calc(var(--spacer) * 6) !important;
  }
  .pl-t-6,
  .pw-t-6 {
    padding-left: calc(var(--spacer) * 6) !important;
  }
  .p-t-6 {
    padding: calc(var(--spacer) * 6) !important;
  }
  .ph-t-7,
  .pt-t-7 {
    padding-top: calc(var(--spacer) * 7) !important;
  }
  .pr-t-7,
  .pw-t-7 {
    padding-right: calc(var(--spacer) * 7) !important;
  }
  .pb-t-7,
  .ph-t-7 {
    padding-bottom: calc(var(--spacer) * 7) !important;
  }
  .pl-t-7,
  .pw-t-7 {
    padding-left: calc(var(--spacer) * 7) !important;
  }
  .p-t-7 {
    padding: calc(var(--spacer) * 7) !important;
  }
  .ph-t-8,
  .pt-t-8 {
    padding-top: calc(var(--spacer) * 8) !important;
  }
  .pr-t-8,
  .pw-t-8 {
    padding-right: calc(var(--spacer) * 8) !important;
  }
  .pb-t-8,
  .ph-t-8 {
    padding-bottom: calc(var(--spacer) * 8) !important;
  }
  .pl-t-8,
  .pw-t-8 {
    padding-left: calc(var(--spacer) * 8) !important;
  }
  .p-t-8 {
    padding: calc(var(--spacer) * 8) !important;
  }
  .ph-t-9,
  .pt-t-9 {
    padding-top: calc(var(--spacer) * 9) !important;
  }
  .pr-t-9,
  .pw-t-9 {
    padding-right: calc(var(--spacer) * 9) !important;
  }
  .pb-t-9,
  .ph-t-9 {
    padding-bottom: calc(var(--spacer) * 9) !important;
  }
  .pl-t-9,
  .pw-t-9 {
    padding-left: calc(var(--spacer) * 9) !important;
  }
  .p-t-9 {
    padding: calc(var(--spacer) * 9) !important;
  }
  .ph-t-10,
  .pt-t-10 {
    padding-top: calc(var(--spacer) * 10) !important;
  }
  .pr-t-10,
  .pw-t-10 {
    padding-right: calc(var(--spacer) * 10) !important;
  }
  .pb-t-10,
  .ph-t-10 {
    padding-bottom: calc(var(--spacer) * 10) !important;
  }
  .pl-t-10,
  .pw-t-10 {
    padding-left: calc(var(--spacer) * 10) !important;
  }
  .p-t-10 {
    padding: calc(var(--spacer) * 10) !important;
  }
  .ph-t-11,
  .pt-t-11 {
    padding-top: calc(var(--spacer) * 11) !important;
  }
  .pr-t-11,
  .pw-t-11 {
    padding-right: calc(var(--spacer) * 11) !important;
  }
  .pb-t-11,
  .ph-t-11 {
    padding-bottom: calc(var(--spacer) * 11) !important;
  }
  .pl-t-11,
  .pw-t-11 {
    padding-left: calc(var(--spacer) * 11) !important;
  }
  .p-t-11 {
    padding: calc(var(--spacer) * 11) !important;
  }
  .ph-t-12,
  .pt-t-12 {
    padding-top: calc(var(--spacer) * 12) !important;
  }
  .pr-t-12,
  .pw-t-12 {
    padding-right: calc(var(--spacer) * 12) !important;
  }
  .pb-t-12,
  .ph-t-12 {
    padding-bottom: calc(var(--spacer) * 12) !important;
  }
  .pl-t-12,
  .pw-t-12 {
    padding-left: calc(var(--spacer) * 12) !important;
  }
  .p-t-12 {
    padding: calc(var(--spacer) * 12) !important;
  }
}
@media (max-width: 768px) {
  .ph-p-0,
  .pt-p-0 {
    padding-top: calc(var(--spacer) * 0) !important;
  }
  .pr-p-0,
  .pw-p-0 {
    padding-right: calc(var(--spacer) * 0) !important;
  }
  .pb-p-0,
  .ph-p-0 {
    padding-bottom: calc(var(--spacer) * 0) !important;
  }
  .pl-p-0,
  .pw-p-0 {
    padding-left: calc(var(--spacer) * 0) !important;
  }
  .p-p-0 {
    padding: calc(var(--spacer) * 0) !important;
  }
  .ph-p-1,
  .pt-p-1 {
    padding-top: calc(var(--spacer) * 1) !important;
  }
  .pr-p-1,
  .pw-p-1 {
    padding-right: calc(var(--spacer) * 1) !important;
  }
  .pb-p-1,
  .ph-p-1 {
    padding-bottom: calc(var(--spacer) * 1) !important;
  }
  .pl-p-1,
  .pw-p-1 {
    padding-left: calc(var(--spacer) * 1) !important;
  }
  .p-p-1 {
    padding: calc(var(--spacer) * 1) !important;
  }
  .ph-p-2,
  .pt-p-2 {
    padding-top: calc(var(--spacer) * 2) !important;
  }
  .pr-p-2,
  .pw-p-2 {
    padding-right: calc(var(--spacer) * 2) !important;
  }
  .pb-p-2,
  .ph-p-2 {
    padding-bottom: calc(var(--spacer) * 2) !important;
  }
  .pl-p-2,
  .pw-p-2 {
    padding-left: calc(var(--spacer) * 2) !important;
  }
  .p-p-2 {
    padding: calc(var(--spacer) * 2) !important;
  }
  .ph-p-3,
  .pt-p-3 {
    padding-top: calc(var(--spacer) * 3) !important;
  }
  .pr-p-3,
  .pw-p-3 {
    padding-right: calc(var(--spacer) * 3) !important;
  }
  .pb-p-3,
  .ph-p-3 {
    padding-bottom: calc(var(--spacer) * 3) !important;
  }
  .pl-p-3,
  .pw-p-3 {
    padding-left: calc(var(--spacer) * 3) !important;
  }
  .p-p-3 {
    padding: calc(var(--spacer) * 3) !important;
  }
  .ph-p-4,
  .pt-p-4 {
    padding-top: calc(var(--spacer) * 4) !important;
  }
  .pr-p-4,
  .pw-p-4 {
    padding-right: calc(var(--spacer) * 4) !important;
  }
  .pb-p-4,
  .ph-p-4 {
    padding-bottom: calc(var(--spacer) * 4) !important;
  }
  .pl-p-4,
  .pw-p-4 {
    padding-left: calc(var(--spacer) * 4) !important;
  }
  .p-p-4 {
    padding: calc(var(--spacer) * 4) !important;
  }
  .ph-p-5,
  .pt-p-5 {
    padding-top: calc(var(--spacer) * 5) !important;
  }
  .pr-p-5,
  .pw-p-5 {
    padding-right: calc(var(--spacer) * 5) !important;
  }
  .pb-p-5,
  .ph-p-5 {
    padding-bottom: calc(var(--spacer) * 5) !important;
  }
  .pl-p-5,
  .pw-p-5 {
    padding-left: calc(var(--spacer) * 5) !important;
  }
  .p-p-5 {
    padding: calc(var(--spacer) * 5) !important;
  }
  .ph-p-6,
  .pt-p-6 {
    padding-top: calc(var(--spacer) * 6) !important;
  }
  .pr-p-6,
  .pw-p-6 {
    padding-right: calc(var(--spacer) * 6) !important;
  }
  .pb-p-6,
  .ph-p-6 {
    padding-bottom: calc(var(--spacer) * 6) !important;
  }
  .pl-p-6,
  .pw-p-6 {
    padding-left: calc(var(--spacer) * 6) !important;
  }
  .p-p-6 {
    padding: calc(var(--spacer) * 6) !important;
  }
  .ph-p-7,
  .pt-p-7 {
    padding-top: calc(var(--spacer) * 7) !important;
  }
  .pr-p-7,
  .pw-p-7 {
    padding-right: calc(var(--spacer) * 7) !important;
  }
  .pb-p-7,
  .ph-p-7 {
    padding-bottom: calc(var(--spacer) * 7) !important;
  }
  .pl-p-7,
  .pw-p-7 {
    padding-left: calc(var(--spacer) * 7) !important;
  }
  .p-p-7 {
    padding: calc(var(--spacer) * 7) !important;
  }
  .ph-p-8,
  .pt-p-8 {
    padding-top: calc(var(--spacer) * 8) !important;
  }
  .pr-p-8,
  .pw-p-8 {
    padding-right: calc(var(--spacer) * 8) !important;
  }
  .pb-p-8,
  .ph-p-8 {
    padding-bottom: calc(var(--spacer) * 8) !important;
  }
  .pl-p-8,
  .pw-p-8 {
    padding-left: calc(var(--spacer) * 8) !important;
  }
  .p-p-8 {
    padding: calc(var(--spacer) * 8) !important;
  }
  .ph-p-9,
  .pt-p-9 {
    padding-top: calc(var(--spacer) * 9) !important;
  }
  .pr-p-9,
  .pw-p-9 {
    padding-right: calc(var(--spacer) * 9) !important;
  }
  .pb-p-9,
  .ph-p-9 {
    padding-bottom: calc(var(--spacer) * 9) !important;
  }
  .pl-p-9,
  .pw-p-9 {
    padding-left: calc(var(--spacer) * 9) !important;
  }
  .p-p-9 {
    padding: calc(var(--spacer) * 9) !important;
  }
  .ph-p-10,
  .pt-p-10 {
    padding-top: calc(var(--spacer) * 10) !important;
  }
  .pr-p-10,
  .pw-p-10 {
    padding-right: calc(var(--spacer) * 10) !important;
  }
  .pb-p-10,
  .ph-p-10 {
    padding-bottom: calc(var(--spacer) * 10) !important;
  }
  .pl-p-10,
  .pw-p-10 {
    padding-left: calc(var(--spacer) * 10) !important;
  }
  .p-p-10 {
    padding: calc(var(--spacer) * 10) !important;
  }
  .ph-p-11,
  .pt-p-11 {
    padding-top: calc(var(--spacer) * 11) !important;
  }
  .pr-p-11,
  .pw-p-11 {
    padding-right: calc(var(--spacer) * 11) !important;
  }
  .pb-p-11,
  .ph-p-11 {
    padding-bottom: calc(var(--spacer) * 11) !important;
  }
  .pl-p-11,
  .pw-p-11 {
    padding-left: calc(var(--spacer) * 11) !important;
  }
  .p-p-11 {
    padding: calc(var(--spacer) * 11) !important;
  }
  .ph-p-12,
  .pt-p-12 {
    padding-top: calc(var(--spacer) * 12) !important;
  }
  .pr-p-12,
  .pw-p-12 {
    padding-right: calc(var(--spacer) * 12) !important;
  }
  .pb-p-12,
  .ph-p-12 {
    padding-bottom: calc(var(--spacer) * 12) !important;
  }
  .pl-p-12,
  .pw-p-12 {
    padding-left: calc(var(--spacer) * 12) !important;
  }
  .p-p-12 {
    padding: calc(var(--spacer) * 12) !important;
  }
}
