.wi-alert {
position: fixed;
width: 300px;
top: var(--alert-top);
right: -100%;
background: var(--alert-bg);
color: var(--alert-tx);
border-radius: var(--alert-border-radius);
border: var(--alert-border-width) solid var(--alert-border-color);
font-size: 16px;
line-height: 16px;
box-sizing: border-box;
pointer-events: auto;
z-index: 999;
transition: 1s;
}
.wi-alert.wi-show {
right: var(--alert-right);
}
.wi-alert .wi-alert-header,
.wi-alert .wi-alert-body {
position: relative;
float: left;
width: 100%;
padding: calc(var(--spacer) * 3); 
box-sizing: border-box;
}
.wi-alert .wi-alert-header i,
.wi-alert .wi-alert-header b {
position: relative;
float: left;
}
.wi-alert .wi-alert-header .wi-alert-icon {
margin-right: 8px;
}
.wi-alert .wi-alert-header .wi-alert-close {
float: right;
cursor: pointer;
}
.wi-alert .wi-alert-body {
border-top: var(--alert-border-width) solid var(--alert-border-color);
}