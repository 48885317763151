/* H1,H2,H3,H4,H5,H6 */
h1,h2,h3,h4,h5,h6, a, p, i {
margin: 0;
padding: 0;
font-size: unset;
font-weight: unset;
color: currentColor;
}

a { cursor: pointer; }

code {
background: var(--secondary-color-40);
color: var(--secondary-color-100);
padding: 4px;
border-radius: var(--button-border-radius);
}

.title-big {
width: 100%;
font-size: var(--title-big-font-size) !important;
line-height: var(--title-big-line-height) !important;
font-family: var(--title-big-font-family) !important;
font-weight: var(--title-big-font-weight) !important;
}
.title {
width: 100%;
font-size: var(--title-font-size) !important;
line-height: var(--title-line-height) !important;
font-family: var(--title-font-family) !important;
font-weight: var(--title-font-weight) !important;
}
.subtitle {
width: 100%;
font-size: var(--subtitle-font-size) !important;
line-height: var(--subtitle-line-height) !important;
font-family: var(--subtitle-font-family) !important;
font-weight: var(--subtitle-font-weight) !important;
}
.text {
width: 100%;
font-size: var(--text-font-size) !important;
line-height: var(--text-line-height) !important;
font-family: var(--text-font-family) !important;
font-weight: var(--text-font-weight) !important;
}
.text-small {
width: 100%;
font-size: var(--text-small-font-size) !important;
line-height: var(--text-small-line-height) !important;
font-family: var(--text-small-font-family) !important;
font-weight: var(--text-small-font-weight) !important;
}

/* Align */
.a-c { text-align: center !important; }
.a-l { text-align: left !important; }
.a-r { text-align: right !important; }

/* Font weight */
.fw-100 { font-weight: 100 !important; }
.fw-200 { font-weight: 200 !important; }
.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700 { font-weight: 700 !important; }
.fw-800 { font-weight: 800 !important; }
.fw-900 { font-weight: 900 !important; }

/* Decoration */
.tx-none { text-decoration: none !important; }
.tx-underline { text-decoration: underline !important; }
.tx-upper { text-transform: uppercase !important; }

/* Max line */
.max-line-1 {
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-2 {
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-3 {
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-4 {
display: -webkit-box;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-5 {
display: -webkit-box;
-webkit-line-clamp: 5;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-6 {
display: -webkit-box;
-webkit-line-clamp: 6;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-7 {
display: -webkit-box;
-webkit-line-clamp: 7;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-8 {
display: -webkit-box;
-webkit-line-clamp: 8;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-9 {
display: -webkit-box;
-webkit-line-clamp: 9;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-10 {
display: -webkit-box;
-webkit-line-clamp: 10;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-11 {
display: -webkit-box;
-webkit-line-clamp: 11;
-webkit-box-orient: vertical;  
overflow: hidden;
}
.max-line-12 {
display: -webkit-box;
-webkit-line-clamp: 12;
-webkit-box-orient: vertical;  
overflow: hidden;
}

@media (max-width: 1000px) {
.t-title-big {
width: 100%;
font-size: var(--title-big-font-size) !important;
line-height: var(--title-big-line-height) !important;
font-family: var(--title-big-font-family) !important;
font-weight: var(--title-big-font-weight) !important;
}
.t-title {
width: 100%;
font-size: var(--title-font-size) !important;
line-height: var(--title-line-height) !important;
font-family: var(--title-font-family) !important;
font-weight: var(--title-font-weight) !important;
}
.t-subtitle {
width: 100%;
font-size: var(--subtitle-font-size) !important;
line-height: var(--subtitle-line-height) !important;
font-family: var(--subtitle-font-family) !important;
font-weight: var(--subtitle-font-weight) !important;
}
.t-text {
width: 100%;
font-size: var(--text-font-size) !important;
line-height: var(--text-line-height) !important;
font-family: var(--text-font-family) !important;
font-weight: var(--text-font-weight) !important;
}
.t-text-small {
width: 100%;
font-size: var(--text-small-font-size) !important;
line-height: var(--text-small-line-height) !important;
font-family: var(--text-small-font-family) !important;
font-weight: var(--text-small-font-weight) !important;
}
/* Align */
.t-a-c { text-align: center !important; }
.t-a-l { text-align: left !important; }
.t-a-r { text-align: right !important; }
/* Font weight */
.fw-t-100 { font-weight: 100 !important; }
.fw-t-200 { font-weight: 200 !important; }
.fw-t-300 { font-weight: 300 !important; }
.fw-t-400 { font-weight: 400 !important; }
.fw-t-500 { font-weight: 500 !important; }
.fw-t-600 { font-weight: 600 !important; }
.fw-t-700 { font-weight: 700 !important; }
.fw-t-800 { font-weight: 800 !important; }
.fw-t-900 { font-weight: 900 !important; }
}

@media (max-width: 768px) {
.p-title-big {
width: 100%;
font-size: var(--title-big-font-size) !important;
line-height: var(--title-big-line-height) !important;
font-family: var(--title-big-font-family) !important;
font-weight: var(--title-big-font-weight) !important;
}
.p-title {
width: 100%;
font-size: var(--title-font-size) !important;
line-height: var(--title-line-height) !important;
font-family: var(--title-font-family) !important;
font-weight: var(--title-font-weight) !important;
}
.p-subtitle {
width: 100%;
font-size: var(--subtitle-font-size) !important;
line-height: var(--subtitle-line-height) !important;
font-family: var(--subtitle-font-family) !important;
font-weight: var(--subtitle-font-weight) !important;
}
.p-text {
width: 100%;
font-size: var(--text-font-size) !important;
line-height: var(--text-line-height) !important;
font-family: var(--text-font-family) !important;
font-weight: var(--text-font-weight) !important;
}
.p-text-small {
width: 100%;
font-size: var(--text-small-font-size) !important;
line-height: var(--text-small-line-height) !important;
font-family: var(--text-small-font-family) !important;
font-weight: var(--text-small-font-weight) !important;
}
/* Align */
.p-a-c { text-align: center !important; }
.p-a-l { text-align: left !important; }
.p-a-r { text-align: right !important; }
/* Font weight */
.fw-p-100 { font-weight: 100 !important; }
.fw-p-200 { font-weight: 200 !important; }
.fw-p-300 { font-weight: 300 !important; }
.fw-p-400 { font-weight: 400 !important; }
.fw-p-500 { font-weight: 500 !important; }
.fw-p-600 { font-weight: 600 !important; }
.fw-p-700 { font-weight: 700 !important; }
.fw-p-800 { font-weight: 800 !important; }
.fw-p-900 { font-weight: 900 !important; }
}