.d-grid.row-max { grid-auto-rows: max-content !important; }
.d-grid.row-min { grid-auto-rows: min-content !important; }

.d-flex.row-max { grid-auto-rows: max-content !important; }
.d-flex.row-min { grid-auto-rows: min-content !important; }

.d-flex.d-column { flex-direction: column !important; }
.d-flex.d-row { flex-direction: row !important; }
.d-flex.d-column-reverse { flex-direction: column-reverse !important; }
.d-flex.d-row-reverse { flex-direction: row-reverse !important; }

.d-grid.col-1 { grid-template-columns: repeat(1, 1fr); }
.d-grid.col-2 { grid-template-columns: repeat(2, 1fr); }
.d-grid.col-3 { grid-template-columns: repeat(3, 1fr); }
.d-grid.col-4 { grid-template-columns: repeat(4, 1fr); }
.d-grid.col-5 { grid-template-columns: repeat(5, 1fr); }
.d-grid.col-6 { grid-template-columns: repeat(6, 1fr); }
.d-grid.col-7 { grid-template-columns: repeat(7, 1fr); }
.d-grid.col-8 { grid-template-columns: repeat(8, 1fr); }
.d-grid.col-9 { grid-template-columns: repeat(9, 1fr); }
.d-grid.col-10 { grid-template-columns: repeat(10, 1fr); }
.d-grid.col-11 { grid-template-columns: repeat(11, 1fr); }
.d-grid.col-12 { grid-template-columns: repeat(12, 1fr); }

.d-flex.col-1 { grid-template-columns: repeat(1, 1fr); }
.d-flex.col-2 { grid-template-columns: repeat(2, 1fr); }
.d-flex.col-3 { grid-template-columns: repeat(3, 1fr); }
.d-flex.col-4 { grid-template-columns: repeat(4, 1fr); }
.d-flex.col-5 { grid-template-columns: repeat(5, 1fr); }
.d-flex.col-6 { grid-template-columns: repeat(6, 1fr); }
.d-flex.col-7 { grid-template-columns: repeat(7, 1fr); }
.d-flex.col-8 { grid-template-columns: repeat(8, 1fr); }
.d-flex.col-9 { grid-template-columns: repeat(9, 1fr); }
.d-flex.col-10 { grid-template-columns: repeat(10, 1fr); }
.d-flex.col-11 { grid-template-columns: repeat(11, 1fr); }
.d-flex.col-12 { grid-template-columns: repeat(12, 1fr); }

.gap-0 { gap: calc(var(--spacer) * 0); }
.gap-1 { gap: calc(var(--spacer) * 1); }
.gap-2 { gap: calc(var(--spacer) * 2); }
.gap-3 { gap: calc(var(--spacer) * 3); }
.gap-4 { gap: calc(var(--spacer) * 4); }
.gap-5 { gap: calc(var(--spacer) * 5); }
.gap-6 { gap: calc(var(--spacer) * 6); }
.gap-7 { gap: calc(var(--spacer) * 7); }
.gap-8 { gap: calc(var(--spacer) * 8); }
.gap-9 { gap: calc(var(--spacer) * 9); }
.gap-10 { gap: calc(var(--spacer) * 10); }
.gap-11 { gap: calc(var(--spacer) * 11); }
.gap-12 { gap: calc(var(--spacer) * 12); }

.r-gap-0 { row-gap: calc(var(--spacer) * 0); }
.r-gap-1 { row-gap: calc(var(--spacer) * 1); }
.r-gap-2 { row-gap: calc(var(--spacer) * 2); }
.r-gap-3 { row-gap: calc(var(--spacer) * 3); }
.r-gap-4 { row-gap: calc(var(--spacer) * 4); }
.r-gap-5 { row-gap: calc(var(--spacer) * 5); }
.r-gap-6 { row-gap: calc(var(--spacer) * 6); }
.r-gap-7 { row-gap: calc(var(--spacer) * 7); }
.r-gap-8 { row-gap: calc(var(--spacer) * 8); }
.r-gap-9 { row-gap: calc(var(--spacer) * 9); }
.r-gap-10 { row-gap: calc(var(--spacer) * 10); }
.r-gap-11 { row-gap: calc(var(--spacer) * 11); }
.r-gap-12 { row-gap: calc(var(--spacer) * 12); }

.col-1 { grid-column: span 1; }
.col-2 { grid-column: span 2; }
.col-3 { grid-column: span 3; }
.col-3 { grid-column: span 3; }
.col-4 { grid-column: span 4; }
.col-5 { grid-column: span 5; }
.col-6 { grid-column: span 6; }
.col-7 { grid-column: span 7; }
.col-8 { grid-column: span 8; }
.col-9 { grid-column: span 9; }
.col-10 { grid-column: span 10; }
.col-11 { grid-column: span 11; }
.col-12 { grid-column: span 12; }

@media (max-width: 1000px) {
.d-grid.row-t-max { grid-auto-rows: max-content !important; }
.d-grid.row-t-min { grid-auto-rows: min-content !important; }

.d-flex.row-t-max { grid-auto-rows: max-content !important; }
.d-flex.row-t-min { grid-auto-rows: min-content !important; }

.d-flex.d-t-column { flex-direction: column !important; }
.d-flex.d-t-row { flex-direction: row !important; }
.d-flex.d-t-column-reverse { flex-direction: column-reverse !important; }
.d-flex.d-t-row-reverse { flex-direction: row-reverse !important; }

.d-grid.col-t-1 { grid-template-columns: repeat(1, 1fr); }
.d-grid.col-t-2 { grid-template-columns: repeat(2, 1fr); }
.d-grid.col-t-3 { grid-template-columns: repeat(3, 1fr); }
.d-grid.col-t-4 { grid-template-columns: repeat(4, 1fr); }
.d-grid.col-t-5 { grid-template-columns: repeat(5, 1fr); }
.d-grid.col-t-6 { grid-template-columns: repeat(6, 1fr); }
.d-grid.col-t-7 { grid-template-columns: repeat(7, 1fr); }
.d-grid.col-t-8 { grid-template-columns: repeat(8, 1fr); }
.d-grid.col-t-9 { grid-template-columns: repeat(9, 1fr); }
.d-grid.col-t-10 { grid-template-columns: repeat(10, 1fr); }
.d-grid.col-t-11 { grid-template-columns: repeat(11, 1fr); }
.d-grid.col-t-12 { grid-template-columns: repeat(12, 1fr); }

.d-flex.col-t-1 { grid-template-columns: repeat(1, 1fr); }
.d-flex.col-t-2 { grid-template-columns: repeat(2, 1fr); }
.d-flex.col-t-3 { grid-template-columns: repeat(3, 1fr); }
.d-flex.col-t-4 { grid-template-columns: repeat(4, 1fr); }
.d-flex.col-t-5 { grid-template-columns: repeat(5, 1fr); }
.d-flex.col-t-6 { grid-template-columns: repeat(6, 1fr); }
.d-flex.col-t-7 { grid-template-columns: repeat(7, 1fr); }
.d-flex.col-t-8 { grid-template-columns: repeat(8, 1fr); }
.d-flex.col-t-9 { grid-template-columns: repeat(9, 1fr); }
.d-flex.col-t-10 { grid-template-columns: repeat(10, 1fr); }
.d-flex.col-t-11 { grid-template-columns: repeat(11, 1fr); }
.d-flex.col-t-12 { grid-template-columns: repeat(12, 1fr); }
        
.gap-t-0 { gap: calc(var(--spacer) * 0); }
.gap-t-1 { gap: calc(var(--spacer) * 1); }
.gap-t-2 { gap: calc(var(--spacer) * 2); }
.gap-t-3 { gap: calc(var(--spacer) * 3); }
.gap-t-4 { gap: calc(var(--spacer) * 4); }
.gap-t-5 { gap: calc(var(--spacer) * 5); }
.gap-t-6 { gap: calc(var(--spacer) * 6); }
.gap-t-7 { gap: calc(var(--spacer) * 7); }
.gap-t-8 { gap: calc(var(--spacer) * 8); }
.gap-t-9 { gap: calc(var(--spacer) * 9); }
.gap-t-10 { gap: calc(var(--spacer) * 10); }
.gap-t-11 { gap: calc(var(--spacer) * 11); }
.gap-t-12 { gap: calc(var(--spacer) * 12); }

.r-gap-t-0 { row-gap: calc(var(--spacer) * 0); }
.r-gap-t-1 { row-gap: calc(var(--spacer) * 1); }
.r-gap-t-2 { row-gap: calc(var(--spacer) * 2); }
.r-gap-t-3 { row-gap: calc(var(--spacer) * 3); }
.r-gap-t-4 { row-gap: calc(var(--spacer) * 4); }
.r-gap-t-5 { row-gap: calc(var(--spacer) * 5); }
.r-gap-t-6 { row-gap: calc(var(--spacer) * 6); }
.r-gap-t-7 { row-gap: calc(var(--spacer) * 7); }
.r-gap-t-8 { row-gap: calc(var(--spacer) * 8); }
.r-gap-t-9 { row-gap: calc(var(--spacer) * 9); }
.r-gap-t-10 { row-gap: calc(var(--spacer) * 10); }
.r-gap-t-11 { row-gap: calc(var(--spacer) * 11); }
.r-gap-t-12 { row-gap: calc(var(--spacer) * 12); }

.col-t-1 { grid-column: span 1; }
.col-t-2 { grid-column: span 2; }
.col-t-3 { grid-column: span 3; }
.col-t-3 { grid-column: span 3; }
.col-t-4 { grid-column: span 4; }
.col-t-5 { grid-column: span 5; }
.col-t-6 { grid-column: span 6; }
.col-t-7 { grid-column: span 7; }
.col-t-8 { grid-column: span 8; }
.col-t-9 { grid-column: span 9; }
.col-t-10 { grid-column: span 10; }
.col-t-11 { grid-column: span 11; }
.col-t-12 { grid-column: span 12; }
}

@media (max-width: 768px) {
.d-grid.row-p-max { grid-auto-rows: max-content !important; }
.d-grid.row-p-min { grid-auto-rows: min-content !important; }

.d-flex.row-p-max { grid-auto-rows: max-content !important; }
.d-flex.row-p-min { grid-auto-rows: min-content !important; }

.d-flex.d-p-column { flex-direction: column !important; }
.d-flex.d-p-row { flex-direction: row !important; }
.d-flex.d-p-column-reverse { flex-direction: column-reverse !important; }
.d-flex.d-p-row-reverse { flex-direction: row-reverse !important; }

.d-grid.col-p-1 { grid-template-columns: repeat(1, 1fr); }
.d-grid.col-p-2 { grid-template-columns: repeat(2, 1fr); }
.d-grid.col-p-3 { grid-template-columns: repeat(3, 1fr); }
.d-grid.col-p-4 { grid-template-columns: repeat(4, 1fr); }
.d-grid.col-p-5 { grid-template-columns: repeat(5, 1fr); }
.d-grid.col-p-6 { grid-template-columns: repeat(6, 1fr); }
.d-grid.col-p-7 { grid-template-columns: repeat(7, 1fr); }
.d-grid.col-p-8 { grid-template-columns: repeat(8, 1fr); }
.d-grid.col-p-9 { grid-template-columns: repeat(9, 1fr); }
.d-grid.col-p-10 { grid-template-columns: repeat(10, 1fr); }
.d-grid.col-p-11 { grid-template-columns: repeat(11, 1fr); }
.d-grid.col-p-12 { grid-template-columns: repeat(12, 1fr); }

.d-flex.col-p-1 { grid-template-columns: repeat(1, 1fr); }
.d-flex.col-p-2 { grid-template-columns: repeat(2, 1fr); }
.d-flex.col-p-3 { grid-template-columns: repeat(3, 1fr); }
.d-flex.col-p-4 { grid-template-columns: repeat(4, 1fr); }
.d-flex.col-p-5 { grid-template-columns: repeat(5, 1fr); }
.d-flex.col-p-6 { grid-template-columns: repeat(6, 1fr); }
.d-flex.col-p-7 { grid-template-columns: repeat(7, 1fr); }
.d-flex.col-p-8 { grid-template-columns: repeat(8, 1fr); }
.d-flex.col-p-9 { grid-template-columns: repeat(9, 1fr); }
.d-flex.col-p-10 { grid-template-columns: repeat(10, 1fr); }
.d-flex.col-p-11 { grid-template-columns: repeat(11, 1fr); }
.d-flex.col-p-12 { grid-template-columns: repeat(12, 1fr); }

.gap-p-0 { gap: calc(var(--spacer) * 0); }
.gap-p-1 { gap: calc(var(--spacer) * 1); }
.gap-p-2 { gap: calc(var(--spacer) * 2); }
.gap-p-3 { gap: calc(var(--spacer) * 3); }
.gap-p-4 { gap: calc(var(--spacer) * 4); }
.gap-p-5 { gap: calc(var(--spacer) * 5); }
.gap-p-6 { gap: calc(var(--spacer) * 6); }
.gap-p-7 { gap: calc(var(--spacer) * 7); }
.gap-p-8 { gap: calc(var(--spacer) * 8); }
.gap-p-9 { gap: calc(var(--spacer) * 9); }
.gap-p-10 { gap: calc(var(--spacer) * 10); }
.gap-p-11 { gap: calc(var(--spacer) * 11); }
.gap-p-12 { gap: calc(var(--spacer) * 12); }

.r-gap-p-0 { row-gap: calc(var(--spacer) * 0); }
.r-gap-p-1 { row-gap: calc(var(--spacer) * 1); }
.r-gap-p-2 { row-gap: calc(var(--spacer) * 2); }
.r-gap-p-3 { row-gap: calc(var(--spacer) * 3); }
.r-gap-p-4 { row-gap: calc(var(--spacer) * 4); }
.r-gap-p-5 { row-gap: calc(var(--spacer) * 5); }
.r-gap-p-6 { row-gap: calc(var(--spacer) * 6); }
.r-gap-p-7 { row-gap: calc(var(--spacer) * 7); }
.r-gap-p-8 { row-gap: calc(var(--spacer) * 8); }
.r-gap-p-9 { row-gap: calc(var(--spacer) * 9); }
.r-gap-p-10 { row-gap: calc(var(--spacer) * 10); }
.r-gap-p-11 { row-gap: calc(var(--spacer) * 11); }
.r-gap-p-12 { row-gap: calc(var(--spacer) * 12); }

.col-p-1 { grid-column: span 1; }
.col-p-2 { grid-column: span 2; }
.col-p-3 { grid-column: span 3; }
.col-p-3 { grid-column: span 3; }
.col-p-4 { grid-column: span 4; }
.col-p-5 { grid-column: span 5; }
.col-p-6 { grid-column: span 6; }
.col-p-7 { grid-column: span 7; }
.col-p-8 { grid-column: span 8; }
.col-p-9 { grid-column: span 9; }
.col-p-10 { grid-column: span 10; }
.col-p-11 { grid-column: span 11; }
.col-p-12 { grid-column: span 12; }
}