section,
footer {
position: relative;
float: left;
width: 100%;
padding: calc(var(--spacer) * 10) 0;
overflow: hidden;
}
.content {
width: calc(100% - 80px);
max-width: 1200px;
left: 50%;
transform: translateX(-50%);
}
.content.content-medium { 
max-width: 700px; 
}
.content.content-little { 
max-width: 500px; 
}
.content.content-small { 
max-width: 400px; 
}
section.full-page { 
height: 100%; 
padding: 0;
}
section.full-page .content { 
height: calc(100% - 80px);
margin: calc(var(--spacer) * 10) 0;
}
section.intro {
margin-top: var(--header-height); 
}
section.full-page.intro { 
height: calc(100% - var(--header-height));
}

@media (max-width: 768px) {
.content { width: calc(100% - calc(var(--spacer) * 10)); }
}