html, body {
width: 100%;
height: 100%;
padding: 0;
margin: 0;
font-family: var(--font-family);
font-weight: var(--font-weight);
font-size: var(--font-size);
line-height: var(--line-height);
background: var(--bg-color);
color: var(--tx-color);
}

body.block-scroll {
overflow: hidden
}

header, 
footer,
header div,
header img,
footer div,
footer img,
section div,
section img,
section table {
position: relative;
float: left;
}

section table {
border-collapse: collapse;
}
section table th,
section table td {
padding: 0 !important;
}