header {
position: fixed;
width: 100%;
top: 0;
left: 0;
height: var(--header-height);
padding: calc(var(--spacer) * 4) 0;
box-sizing: border-box;
box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
z-index: 99;
}
header .content {
height: 100%;
}
header .nav-list {
line-height: calc(var(--header-height) - (var(--spacer) * 8));
}
header .nav-list .nav {
position: relative;
float: left;
padding: 0 calc(var(--spacer) * 3);
text-decoration: none;
}
header .nav-list .nav:first-child {
padding-left: 0;
}
header .nav-list .nav:last-child {
padding-right: 0;
}

.scroll-line {
width: 2px; 
height: 40px;
left: 50%;
transform: translateX(-50%);
}

.scroll-line:after {
content: '';
position: absolute;
float: left;
left: 0;
bottom: 0;
width: 100%;
height: 100%;
background: #ffffff;
animation-name: scroll-line;
animation-duration: 1s;
animation-delay: .3s;
animation-iteration-count: infinite;
animation-direction: alternate;
}

@keyframes scroll-line {
from { height: 100%; }
to { height: 40%; }
}
