.spinner-border {
display: inline-block;
width: 1em;
height: 1em;
vertical-align: -0.125em;
border-radius: 50%;
border: 2px solid currentColor;
border-right-color: transparent !important;
animation: 0.75s linear infinite spinner-border;
box-sizing: border-box;
}

@keyframes spinner-border {
to { transform: rotate(360deg) }
}

.spinner-grow {
display: inline-block;
width: 1em;
height: 1em;
vertical-align: -0.125em;
border-radius: 50%;
background: currentColor;
opacity: 0;
animation: 0.75s linear infinite spinner-grow;
box-sizing: border-box;
}

@keyframes spinner-grow {
0% {
    transform: scale(0);
}
50% {
    opacity: 1;
    transform: none;
}
}