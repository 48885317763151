/* Button */
.btn-group,
.badge-group {
display: flex !important;
flex-wrap: wrap !important;
width: 100%;
gap: calc(var(--spacer) * 2);
}

.btn {
position: relative;
float: left;
width: auto;
padding: 10px 12px !important;
border-width: var(--button-border-width);
border-style: solid;
border-radius: var(--button-border-radius);
box-sizing: border-box !important;
text-decoration: none !important;
font-size: var(--button-font-size) !important;
font-weight: var(--button-font-weight) !important;
line-height: var(--button-line-height) !important;
transition: .3s;
cursor: pointer;
-webkit-touch-callout: none !important;
-webkit-user-select: none !important;
-khtml-user-select: none !important;
-moz-user-select: none !important;
-ms-user-select: none !important;
user-select: none !important; 
}
.btn.btn-lg {
padding: 16px !important;
}
.btn.btn-sm {
padding: 5px 6px !important;
}
.btn:hover {
text-decoration: none !important;
}
.btn.wi-submit {
border-radius: var(--input-border-radius) !important;
}
.btn:disabled {
opacity: .6;
cursor: not-allowed;
}


/* Button icon */
.btn.btn-icon {
padding: 10px !important;
width: calc(var(--button-line-height) + (var(--button-border-width) * 2) + 20px) !important;
text-align: center !important;
}
.btn.btn-icon-right i,
.btn.btn-arrow i,
.btn.btn-icon-left i {
position: relative;
float: left;
height: var(--button-line-height);
font-size: var(--button-line-height);
line-height: var(--button-line-height);
transition: .3s;
}
.btn.btn-arrow i,
.btn.btn-icon-right i {
float: right;
margin-left: 6px;
}
.btn.btn-icon-left i {
margin-right: 6px;
}
.btn.btn-arrow:hover {
padding-right: 7px !important;
}
.btn.btn-arrow:hover i {
margin-left: 11px;
}


/* Badge */
.badge {
position: relative;
float: left;
width: auto;
font-size: var(--badge-font-size) !important;
font-weight: var(--badge-font-weight) !important;
line-height: var(--badge-line-height) !important;
padding: 3px 6px;
border-width: var(--badge-border-width) !important;
border-style: solid;
border-radius: var(--badge-border-radius);
text-decoration: none;
box-sizing: border-box !important;
-webkit-touch-callout: none !important;
-webkit-user-select: none !important;
-khtml-user-select: none !important;
-moz-user-select: none !important;
-ms-user-select: none !important;
user-select: none !important; 
}
.badge.badge-icon {
padding: 3px !important;
width: calc(var(--badge-line-height) + (var(--badge-border-width) * 2) + 6px) !important;
text-align: center !important;
}